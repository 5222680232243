import React, { useState, useRef, useEffect } from "react";
import {
  Alert,
  Button,
  FormControl,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import "./LoginPage.scss";
import { Link, useNavigate } from "react-router-dom";
import { POST_LOGIN_OTP, POST_LOGIN_OTP_VERIFY } from "../../config/endpoints";
import LoadingButton from "../LoadingButton/LoadingButton";
import { handleLoginError, handleOtpError } from '../../config/errors'

import { SERVER_MESSAGE } from "../../config/settings";


const LoginPage = ({ onCancelDialog }) => {
  const navigate = useNavigate();


  const [showOTPBox, setShowOTPBox] = useState(false);
  const [otp, setOTP] = useState(["", "", "", ""]);
  const [resendDisabled, setResendDisabled] = useState(false);
  const [timer, setTimer] = useState(60);
  const [mobileNumber, setMobileNumber] = useState("");
  const [countryCode, setcountryCode] = useState("+91")
  const otpInputs = useRef(Array(6).fill(null));
  const [signupOTP, setSignupOTP] = useState(false);
  const [isError, setIsError] = useState(false)
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [alertMsg, setAlertMsg] = useState("")
  const [redirectionPath, setRedirectionPath] = useState('/')



  //Redirection after some 3 sec
  useEffect(() => {
    if (isSuccess) {
      const redirectTimeout = setTimeout(() => {
        navigate(redirectionPath);
        onCancelDialog();
      }, 1000);
      return () => clearTimeout(redirectTimeout);
    }
  }, [isSuccess]);

  let intervalId;
  //resend timer
  useEffect(() => {
    if (resendDisabled) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer === 0) {
            clearInterval(intervalId);
            setResendDisabled(false);
            return 60;
          } else {
            return prevTimer - 1;
          }
        });
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [resendDisabled]);


  //SEND OTP HANDLER
  const handleSendOTP = () => {
    setErrorMsg('');
    setAlertMsg("")
    // If mobile number is not valid, set the error state to true
    if (!/^\d{10}$/.test(mobileNumber)) {
      setIsError(true)
      setErrorMsg("Please enter valid mobile number")
      return; // Exit the function if mobile number is not valid
    }
    // Login OTP API Call:
    setLoading(true);
    var obj = {
      "type": signupOTP ? 'registration' : 'login',
      "phone": {
        "cc": countryCode,
        "number": mobileNumber
      }
    }
    console.log(obj)
    var apiUrl = new URL(POST_LOGIN_OTP);
    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(obj)
    }).then(res => {
      return res.json();
    }).then(response => {
      console.log("LOGIN-OTP RESPONSE: ", response);
      const errorMsg = SERVER_MESSAGE ? response.message : handleOtpError(response)
      console.log(errorMsg)
      if (response && 'code' in response && response['code'] == 200) {
        if (signupOTP) {
          localStorage.setItem('SIGN_UP_BQS__ID', response?.data["_id"]);
          setRedirectionPath("/signup")
          setIsSuccess(true)
          setAlertMsg(errorMsg || "OTP has been sent. Redirecting to sign up page.")
        } else {
          setShowOTPBox(true);
          setResendDisabled(true);
          setErrorMsg(errorMsg || "OTP has been sent");
          setIsError(false)
        }

      } else {
        console.log("Error while generating OTP: ", response);
        setErrorMsg(errorMsg || "Something went wrong");
        setIsError(true)
      }
    }).catch(error => {
      setErrorMsg(error.message || "something went wrong");
      setIsError(true)
    }).finally(() => setLoading(false));
  };


  //VERIFY OTP HANDLER
  const handleVerifyOTP = () => {
    // OTP validation
    let optInString = otp.filter(Boolean).join("");
    if (!optInString) {
      setErrorMsg("Please enter otp received on your mobile number")
      setIsError(true)
      return
    } else if (optInString.length < 4) {
      setErrorMsg("Please Enter valid Otp")
      setIsError(true)
      return
    }
    setLoading(true);
    var obj = {
      "phone": {
        "cc": "+91",
        "number": mobileNumber
      },
      "otp": optInString,
    }
    var apiUrl = new URL(POST_LOGIN_OTP_VERIFY);
    fetch(apiUrl, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(obj)
    }).then(res => {
      return res.json();
    }).then(response => {
      console.log("LOGIN-OTP RESPONSE: ", response);
      const loginErrorMsg = SERVER_MESSAGE ? response.message : handleLoginError(response)
      if (response && 'code' in response && response['code'] == 200) {
        localStorage.setItem('BQS__ID', response.data["_id"]);
        localStorage.setItem('BQS_TOKEN', response.data.token.token);
        localStorage.setItem('BQS_ROLE', response.data.role);
        localStorage.setItem('BQS_USER_NAME', response.data.profileData.name.firstName);
        setRedirectionPath("/")
        setIsSuccess(true)
        setAlertMsg(loginErrorMsg || "OTP Verified! You're now logged in.")
        // onCancelDialog();
      } else {
        //clearing resend timeout and disable resent button
        console.log("Error >>>>")
        clearInterval(intervalId);
        setResendDisabled(true)
        setErrorMsg(loginErrorMsg || `Oops! Something went wrong. Please try again later`);
        setIsError(true)
      }
    }).catch(error => {
      setErrorMsg(error.message || "Oops! Something went wrong. Please try again later");
      setIsError(true)
    }).finally(() => setLoading(false));
  };

  const handleResendOTP = () => {
    //calling handleSendOTP method
    handleSendOTP()
    setTimer(60);
    setResendDisabled(true);
    // //to show alert msg
    // alertMsg(`OTP has been resent successfully`)
    // setIsSuccess(true)
  };

  const handleChangeMobileNumber = (e) => {
    const value = e.target.value;
    setErrorMsg('');
    if (/^\d*$/.test(value) && mobileNumber.length < 10) {
      setMobileNumber(value);
    }
  }

  const handleOnKeyDownMobileNumber = (e) => {
    if (e.key === "Backspace") {
      setMobileNumber(mobileNumber.slice(0, -1));
    }
  }

  const handleChangeOTP = (index, value) => {
    setErrorMsg('');
    const newOTP = [...otp];
    newOTP[index] = value;
    setOTP(newOTP);

    // Move focus to the previous input field if the current value is empty and the user presses the backspace key
    if (value === '' && index > 0) {
      otpInputs.current[index - 1].focus();
    }

    // Move focus to the next input field
    if (value !== '' && index < otp.length - 1) {
      otpInputs.current[index + 1].focus();
    }
  };

  const closeSignup = () => {
    setSignupOTP(!signupOTP);
    setErrorMsg("")
  }

  const handleChangeCountryCode = (event) => {
    setcountryCode(event.target.value);
  }

  return (
    <div className="login-page">
      {/* //Alert*/}
      {
        isSuccess && alertMsg && <Alert sx={{ maxWidth: "100%" }}>
          {alertMsg}
        </Alert>
      }

      {
        !showOTPBox && (
          <>
            {
              signupOTP ? null :
                <>
                  <section className="header-section">
                    <div className="heading">
                      <h1 >Sign In to Visist.ai</h1>
                    </div>
                    <div className="divider"></div>
                  </section>
                  {/* <section className="login-options-section">
                    <div className="login-options">
                      <button>
                        <i className="fab fa-google"></i>{" "}
                      </button>
                      <button>
                        <i className="fab fa-facebook"></i>
                      </button>
                    </div>
                  </section> */}
                </>
            }
            <section className="mobile-login-section">
              {/* {
                signupOTP ?
                  <p>Generate Signup OTP</p>
                  :
                  <p>or login with OTP</p>
              } */}
              <div className="mobile-login">
                <h5 variant="h2">Enter your mobile number</h5>
                <div className="input_wrp">
                  <div className="country-code-input">
                    <FormControl>
                      <Select
                        labelId="country-code-label"
                        label="Country Code"
                        value={countryCode}
                        onChange={handleChangeCountryCode}
                      >
                        <MenuItem value="+91">+91</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="mobile-number-input">
                    <TextField
                      variant="outlined"
                      label="Mobile Number"
                      value={mobileNumber}
                      type="tel"
                      pattern="[0-9]*"
                      inputmode="numeric"
                      onChange={(e) => handleChangeMobileNumber(e)}
                      onKeyDown={(e) => handleOnKeyDownMobileNumber(e)}
                      sx={{
                        fontSize: {
                          xs: '14px',
                          sm: '18px',
                          md: '20px',
                          lg: '24px',
                        },
                        color: "#000000"
                      }}
                    />
                  </div>
                </div>
                <p>
                  By signing up, you agree to our <Link to="/privacy_policy">Privacy Policy</Link> and <Link to="/term_service">Terms of Service.</Link>
                </p>

                <p style={{ color: `${isError ? "red" : "green"}`, fontSize: 14 }}>{errorMsg}</p>

                <Button
                  variant="contained"
                  className="otpBtn"
                  fullWidth
                  onClick={handleSendOTP}
                  disabled={loading}
                >
                  {loading ? <LoadingButton /> : "Send OTP"}
                </Button>
                {
                  signupOTP ?
                    <p>
                      Already have an account?<Link to='' onClick={closeSignup} > Login</Link>{" "}
                    </p>
                    :
                    <p>
                      Don’t have an account?<Link to='/signup' >Sign up</Link>{" "}
                    </p>
                }
              </div>
            </section>
          </>
        )
      }
      {
        showOTPBox && (
          <>
            <section className="header-section">
              <div className="heading">
                <h5 variant="h1">Login with OTP</h5>
              </div>
            </section>
            <section className="otp-section">
              <h5 variant="h2">Enter OTP</h5>
              <div className="otp-inputs">
                {otp.map((digit, index) => (
                  <input
                    key={index}
                    ref={(el) => (otpInputs.current[index] = el)}
                    type="tel"
                    pattern="[0-9]*"
                    inputmode="numeric"
                    value={digit}
                    onChange={(e) => handleChangeOTP(index, e.target.value)}
                    maxLength={1}

                  />
                ))}
              </div>
              <p>Enter the OTP sent to +91{mobileNumber}</p>
              <p style={{ color: `${isError ? "red" : "green"}`, fontSize: 12, textAlign: "center", marginTop: "10px", maxWidth: "250px" }}>{errorMsg}</p>
              <p className="resend_otp">
                {resendDisabled ? (
                  `Resend OTP in ${timer} seconds`
                ) : (
                  <a href="#" onClick={handleResendOTP}>
                    Resend OTP
                  </a>
                )}
              </p>
              <Button
                variant="contained"
                className="otpBtn"
                fullWidth
                onClick={handleVerifyOTP}
                disabled={loading || isSuccess}
              >
                {loading ? <LoadingButton /> : "Login"}
              </Button>
            </section>
          </>
        )
      }
    </div >
  );
};

export default LoginPage;
