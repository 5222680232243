import React, { useEffect, useMemo, useState } from 'react'
import './PersonalDetails.scss'
import dummyProfile from '../dummyProfile.png'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { UpdateUserProfile, UpdateUserProfilePic, getUserProfile, getUserProfilePic } from '../../../redux/network/userApi';
import { setAlertMessage, setAlertType } from '../../../redux/reducers/alertSlice';
import { Box, CircularProgress } from '@mui/material';

const PersonalDetails = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const jwtToken = localStorage.getItem("BQS_TOKEN");
    const UserId = localStorage.getItem("BQS__ID");
    console.log("userId", UserId);

    useEffect(() => {
        if (jwtToken) {
            dispatch(getUserProfile(jwtToken));
        } else {
            navigate("/");
        }
    }, [jwtToken]);

    useEffect(() => {
        if (jwtToken) {
            dispatch(getUserProfilePic(jwtToken));
        } else {
            navigate("/");
        }
    }, [jwtToken]);

    const userData = useSelector((state) => state.user.profile);
    const userPicData = useSelector((state) => state.user.profilePic);

    const { isProfileStarted, isProfilePicError, profilePicError, profilePicUrl } = userPicData;
    const { isStarted, isError, error, value } = userData;
    const { profileData, phone, email } = value;

    console.log("userPicData", userPicData);

    const getFullname = (Fname, Mname, LName) => {
        let fullname = "";

        if (Fname && Fname.length >= 1) {
            fullname += Fname;
        }

        if (Mname && Mname.length >= 1) {
            if (fullname.length > 0) {
                fullname += " " + Mname;
            } else {
                fullname = Mname;
            }
        }

        if (LName && LName.length >= 1) {
            if (fullname.length > 0) {
                fullname += " " + LName;
            } else {
                fullname = LName;
            }
        }
        return fullname;
    };

    console.log("userData", userData);

    const initialState = useMemo(() => {
        return {
            email: email?.email,
            dob: profileData?.dob,
            gender: profileData?.gender,
            height: profileData?.height,
            fullname: getFullname(profileData?.name?.firstName, profileData?.name?.middleName, profileData?.name?.lastName),
            weight: profileData?.weight,
            areasOfImprovement: profileData?.gamingProfile?.areasOfImprovement || [],
            exp: profileData?.gamingProfile?.exp,
            goal: profileData?.gamingProfile?.goal,
            coachName: profileData?.gamingProfile?.coachName,
            injurySummary: profileData?.gamingProfile?.injurySummary,
            _id: UserId
        };
    }, [userData]);

    const [formData, setFormData] = useState(initialState);
    const [errorMsg, setErrorMsg] = useState("");
    const [isPersonalDetailsEditable, setIsPersonalDetailsEditable] = useState(false);
    const [isAdditionalDetailsEditable, setIsAdditionalDetailsEditable] = useState(false);

    //set the formData when userData change
    useEffect(() => {
        setFormData(initialState);
    }, [initialState]);

    console.log("formData", formData);

    const handleUpdateProfilePic = (e) => {
        const file = e.target.files[0];
        console.log(file);
        dispatch(UpdateUserProfilePic(jwtToken, file));
    };

    const handleInputChange = (event) => {
        const { value, name } = event.target;
        console.log(value, name);
        if (name === "dob") {
            const nameInput = value.split(" ");

            if (value === "") {
                // Clear error message and update form data
                setErrorMsg("");
                const newData = { ...formData };
                newData["dob"] = value;
                setFormData(newData);
            } else {
                let formattedDate;
                if (value.length < formData["dob"].length) {
                    // Handle backspace when length is decreasing
                    const newData = { ...formData };
                    newData["dob"] = value;
                    setFormData(newData);
                } else if (value.length === 2) {
                    // Day validation
                    if (parseInt(value) > 31) {
                        setErrorMsg("Please enter a correct Day");
                    } else {
                        formattedDate = value + "-";
                        const newData = { ...formData };
                        newData["dob"] = formattedDate;
                        setFormData(newData);
                    }
                } else if (value.length === 5) {
                    // Month validation
                    if (value.slice(3) > 12) {
                        setErrorMsg("Please enter a correct Month");
                    } else {
                        formattedDate = value + "-";
                        const newData = { ...formData };
                        newData["dob"] = formattedDate;
                        setFormData(newData);
                    }
                } else if (value.length === 11) {
                    // Year validation
                    const year = parseInt(value.slice(6));
                    const currentYear = new Date().getFullYear();
                    if (year > currentYear) {
                        setErrorMsg("Please enter a correct Year");
                    }
                } else {
                    const newData = { ...formData };
                    newData["dob"] = value;
                    setFormData(newData);
                }
            }
        } else {
            setFormData({
                ...formData,
                [name]: value
            });
        }
    };

    const handleSavePersonalDetails = () => {
        setIsPersonalDetailsEditable(!isPersonalDetailsEditable);
        //name validation
        if (formData.fullname.length <= 0) {
            dispatch(setAlertMessage("Please enter name"));
            dispatch(setAlertType("error"));
            return;
        }

        //email validation
        if (!formData.email) {
            dispatch(setAlertMessage("Please enter email"));
            dispatch(setAlertType("error"));
            return;
        }
        if (!/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(formData.email)) {
            dispatch(setAlertMessage("Please enter a valid email address"));
            dispatch(setAlertType("error"));
            return;
        }

        // dob validation
        if (/^\d{2}\-\d{2}\-\d{4}$/.test(formData.dob)) {
            // Parse the entered date string into a Date object
            const parts = formData.dob.split('-');
            const enteredDate = new Date(parts[2], parts[1] - 1, parts[0]); // Note: Month is 0-based
            // Get today's date
            const today = new Date();
            today.setHours(0, 0, 0, 0); // Set time to midnight for comparison
            console.log(enteredDate);
            // Compare the entered date with today's date
            if (enteredDate > today) {
                dispatch(setAlertMessage("Date cannot be greater than today"));
                dispatch(setAlertType("error"));
                return;
            }
        } else {
            dispatch(setAlertMessage("Please enter a valid date in DD-MM-YYYY format"));
            dispatch(setAlertType("error"));
            return;
        }

        //Splitting full name into firstName, MiddleName, and LastName
        const fullnameArr = formData.fullname.trim().split(" ");

        let firstName = "";
        let middleName = "";
        let lastName = "";

        if (fullnameArr.length === 1) {
            firstName = fullnameArr[0];
        } else if (fullnameArr.length === 2) {
            firstName = fullnameArr[0];
            lastName = fullnameArr[1];
        } else if (fullnameArr.length === 3) {
            firstName = fullnameArr[0];
            middleName = fullnameArr[1];
            lastName = fullnameArr[2];
        } else {
            firstName = fullnameArr[0];
            middleName = fullnameArr[1];
            lastName = fullnameArr.slice(2).join(" "); // Join the rest of the array elements into lastName
        }

        console.log("firstname", firstName);
        console.log("lastname", lastName);
        console.log("middlename", middleName);

        //Update API Call

        const body = {
            email: {
                email: formData.email
            },
            profileData: {
                dob: formData.dob,
                gender: formData.gender,
                height: formData.height,
                name: {
                    firstName: firstName,
                    middleName: middleName,
                    lastName: lastName
                },
                weight: formData.weight
            }
        };

        console.log("Personal Details Body", body);
        dispatch(UpdateUserProfile(jwtToken, body));

        // Exit edit mode after saving
        setIsPersonalDetailsEditable(false);
    };

    const handleSaveAdditionalDetails = () => {
        setIsAdditionalDetailsEditable(!isAdditionalDetailsEditable);
        // Construct the body for additional details
        const body = {
            profileData: {
                gamingProfile: {
                    areasOfImprovement: formData.areasOfImprovement,
                    exp: formData.exp,
                    goal: formData.goal,
                    injurySummary: formData.injurySummary,
                    coachName: formData.coachName,
                    _id: UserId
                }
            }
        };

        console.log("Additional Details Body", body);
        dispatch(UpdateUserProfile(jwtToken, body));

        // Exit edit mode after saving
        setIsAdditionalDetailsEditable(false);
    };

    const togglePersonalDetailsEdit = () => {
        setIsPersonalDetailsEditable(!isPersonalDetailsEditable);
    };

    const toggleAdditionalDetailsEdit = () => {
        setIsAdditionalDetailsEditable(!isAdditionalDetailsEditable);
    };

    return (
        <div className='personalDetails_con'>
            <h1 className='heading'>Profile</h1>
            <div className='content_con'>
                <div className='content_box'>
                    <div className='content_header'>
                        <h2>Personal Details</h2>
                        {
                            isPersonalDetailsEditable ? <button onClick={handleSavePersonalDetails}>Save<i class="fa-regular fa-floppy-disk"></i></button> : <button onClick={togglePersonalDetailsEdit}>Edit<i className="fa-solid fa-file-pen"></i></button>
                        }
                    </div>
                    <div className='photo_container'>
                        <div className='img_cont'>
                            {isProfileStarted ? (
                                <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                    <CircularProgress />
                                    <p>Loading Img... </p>
                                </Box>
                            ) : (
                                <div
                                    style={{
                                        backgroundImage: `url(${isProfilePicError ? dummyProfile : profilePicUrl})`,
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                    }}
                                    className='image-box'
                                />
                            )}
                        </div>

                        <label htmlFor='profileImg'>Change</label>
                        <input id='profileImg' type="file" accept="image/*" onChange={handleUpdateProfilePic} />
                    </div>
                    <div className='personal-fields-container'>
                        <div className='input-field-cont'>
                            <label htmlFor='name'>Name</label>
                            <input
                                id='name'
                                type='text'
                                value={formData.fullname}
                                name='fullname'
                                disabled={!isPersonalDetailsEditable}
                                onChange={handleInputChange}
                                onFocus={(e) => e.target.classList.add('focused')}
                            />
                        </div>
                        <div className='input-field-cont'>
                            <label htmlFor='dob'>Date of Birth</label>
                            <input
                                id="dob"
                                type='text'
                                value={formData?.dob}
                                disabled={!isPersonalDetailsEditable}
                                onChange={handleInputChange}
                                name='dob'
                            />
                        </div>
                        <div className='input-field-cont'>
                            <label htmlFor='gender'>Gender</label>
                            <select
                                id='gender'
                                value={formData?.gender}
                                onChange={handleInputChange}
                                name='gender'
                                disabled={!isPersonalDetailsEditable}
                            >
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                                <option value="Other">Other</option>
                            </select>
                        </div>
                        <div className='input-field-cont'>
                            <label htmlFor='weight'>Weight</label>
                            <input
                                id='weight'
                                type='text'
                                value={formData.weight}
                                name='weight'
                                disabled={!isPersonalDetailsEditable}
                                onChange={handleInputChange}
                                onFocus={(e) => e.target.classList.add('focused')}
                            />
                        </div>
                        <div className='input-field-cont'>
                            <label htmlFor='email'>Email</label>
                            <input
                                id="email"
                                type='text'
                                value={formData.email}
                                name='email'
                                disabled={!isPersonalDetailsEditable}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className='input-field-cont'>
                            <label htmlFor='phone'>Phone Number</label>
                            <div className='mobile-number-field-con'>
                                <select disabled>
                                    <option>+91</option>
                                </select>
                                <div></div>
                                <input
                                    id='phone'
                                    type='text'
                                    value={phone?.number}
                                    disabled
                                    style={{ width: "100%" }}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='content_box'>
                    <div className='content_header'>
                        <h2>Additional Details</h2>

                        {
                            isAdditionalDetailsEditable ?
                                <button onClick={handleSaveAdditionalDetails}>
                                    Save
                                    <i class="fa-regular fa-floppy-disk"></i>
                                </button> :
                                <button onClick={toggleAdditionalDetailsEdit}>Edit  <i className="fa-solid fa-file-pen"></i></button>
                        }

                    </div>
                    <div className='additional-fields-container'>
                        <div className='sub-input-field-cont'>
                            <div className='input-field-cont'>
                                <label htmlFor='exp'>Badminton Experience</label>
                                <select
                                    id="exp"
                                    value={formData?.exp}
                                    onChange={handleInputChange}
                                    name='exp'
                                    disabled={!isAdditionalDetailsEditable}
                                >
                                    <option value="Beginner">Beginner</option>
                                    <option value="Intermediate">Intermediate</option>
                                    <option value="Experienced">Experienced</option>
                                </select>
                            </div>
                            <div className='input-field-cont'>
                                <label htmlFor='headCoach'>Head Coach of your Academy</label>
                                <input
                                    id='headCoach'
                                    type='text'
                                    value={formData.coachName}
                                    name='coachName'
                                    disabled={!isAdditionalDetailsEditable}
                                    onChange={handleInputChange}
                                    onFocus={(e) => e.target.classList.add('focused')}
                                />
                            </div>
                        </div>

                        <div className='input-field-cont'>
                            <label htmlFor="goals">What are your goals with badminton?</label>
                            <textarea
                                id='goals'
                                placeholder='What are your goals with badminton?...'
                                value={formData?.goal}
                                onChange={handleInputChange}
                                name='goal'
                                disabled={!isAdditionalDetailsEditable}
                                maxLength={256}
                            />
                        </div>
                        <div className='input-field-cont'>
                            <label htmlFor='injurySummary'>Do you have any present or past injuries? If yes, please specify.</label>
                            <textarea
                                id="injurySummary"
                                placeholder='Do you have any present or past injuries? If yes, please specify...'
                                value={formData?.injurySummary}
                                onChange={handleInputChange}
                                name='injurySummary'
                                disabled={!isAdditionalDetailsEditable}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PersonalDetails;
