import React, { useEffect } from 'react';
import './PrivacyPolicy.scss';
import { useDispatch } from 'react-redux';
import { setIsLoginDialog } from '../../redux/reducers/loginSlice';

const PrivacyPolicy = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(setIsLoginDialog(false))
    }, []);
    return (
        <div className='PrivacyPolicy'>
            <div className='PrivacyPolicy_bannar'>
                <div className='container'>
                    <h1 className='heading'>Privacy Policy</h1>
                </div>
            </div>
            <div className='container_privacy container'>


                <p className='date'>Last updated: 23-Apr-2024</p>
                <p>This privacy notice for <a href='www.visist.ai' target='_blank'>www.visist.ai</a> ("we," "us," or "our"), describes how and why we might collect, store, use, and/or share ("process") your information when you use our services ("Services"), such as when you:</p>
                <p>Questions or concerns? Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services.</p>

                <h2>SUMMARY OF KEY POINTS</h2>
                <p>This summary provides key points from our privacy notice, but you can find out more details about any of these topics by clicking the link following each key point or by using our table of contents below to find the section you are looking for.</p>

                <h3>1. WHAT INFORMATION DO WE COLLECT?</h3>
                <p><strong>Personal information you disclose to us:</strong><br />
                    We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us. All personal information that you provide to us must be true, complete, and accurate.</p>
                <p><strong>Information automatically collected:</strong><br />
                    We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services, and other technical information.</p>

                <h3>2. HOW DO WE PROCESS YOUR INFORMATION?</h3>
                <p>We process your personal information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with the law. We may also process your information for other purposes with your consent.</p>

                <h3>3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h3>
                <p>We may need to share your personal information in specific situations described in this section and/or with the following third parties:</p>
                <ul>
                    <li><strong>Business Transfers:</strong> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</li>
                    <li><strong>Affiliates:</strong> We may share your information with our affiliates, including our parent company and any subsidiaries, joint venture partners, or other companies that we control or that are under common control with us.</li>
                    <li><strong>Business Partners:</strong> We may share your information with our business partners to offer you certain products, services, or promotions.</li>
                </ul>

                <h3>4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h3>
                <p>We may use cookies and similar tracking technologies to collect and store your information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice.</p>

                <h3>5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</h3>
                <p>If you choose to register or log in to our Services using a social media account, we may have access to certain information about you. We will use the information we receive only for the purposes described in this privacy notice or that are otherwise made clear to you on the relevant Services.</p>

                <h3>6. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</h3>
                <p>We may transfer, store, and process your information in countries other than your own. If you are accessing our Services from outside India, please be aware that your information may be transferred to, stored, and processed by us and our third-party service providers in India and other countries.</p>

                <h3>7. HOW LONG DO WE KEEP YOUR INFORMATION?</h3>
                <p>We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law.</p>

                <h3>8. DO WE COLLECT INFORMATION FROM MINORS?</h3>
                <p>We do not knowingly collect data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 years old or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Services.</p>

                <h3>9. WHAT ARE YOUR PRIVACY RIGHTS?</h3>
                <p>You may review, change, or terminate your account at any time. If we are relying on your consent to process your personal information, you have the right to withdraw your consent at any time.</p>

                <h3>10. CONTROLS FOR DO-NOT-TRACK FEATURES</h3>
                <p>At this stage, we do not currently respond to Do-Not-Track browser signals or any other mechanism that automatically communicates your choice not to be tracked online.</p>

                <h3>11. DO WE MAKE UPDATES TO THIS NOTICE?</h3>
                <p>Yes, we may update this privacy notice from time to time to stay compliant with relevant laws.</p>

                <h3>12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h3>
                <p>If you have questions or comments about this notice, you may contact us by post at: <a href="mailto:info@visist.ai">info@visist.ai</a></p>

                <h3>13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</h3>
                <p>Based on the applicable laws of India, you may have the right to request access to the personal information we collect from you, change that information, or delete it. To request to review, update, or delete your personal information, please fill out and submit a data subject access request.</p>
            </div>
        </div>
    );
}

export default PrivacyPolicy;
