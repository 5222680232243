import { createSlice } from "@reduxjs/toolkit";

export const loginSlice = createSlice({
    name: "login",
    initialState: {
        isLoginDialog: false,
    },
    reducers: {
        setIsLoginDialog(state, action) {
            console.log(action)
            state.isLoginDialog = action.payload;
        },
    }
})
export const { setIsLoginDialog } = loginSlice.actions;
export default loginSlice.reducer;