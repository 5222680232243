import { createSlice } from '@reduxjs/toolkit'

export const coachSlice = createSlice({
    name: `coach`,
    initialState: {
        coachList: {
            isStarted: false,
            isError: false,
            error: '',
            value: [],
        },
    },
    reducers: {
        isStarted_getCoachList: (state) => {
            state.coachList.isStarted = true;
            state.coachList.isError = false;
        },
        success_getCoachList: (state, action) => {
            let { payload } = action;
            state.coachList.isError = false;
            state.coachList.isStarted = false;
            state.coachList.error = '';
            state.coachList.value = payload;
        },
        error_getCoachList: (state, action) => {
            let { payload } = action;
            state.coachList.isError = true;
            state.coachList.isStarted = false;
            state.coachList.error = payload;
            state.coachList.value = '';
        },
    }
})

export const { isStarted_getCoachList, success_getCoachList, error_getCoachList } = coachSlice.actions
export default coachSlice.reducer