import React, { useEffect, useState } from "react";
import { Alert, Button, Container, Grid, TextField, Typography } from "@mui/material";
import "./SignupPage.scss"; // Import your CSS file
import { POST_LOGIN_OTP, POST_REGISTER, POST_REGISTER_LOGIN } from '../../config/endpoints';
import LoadingButton from "../LoadingButton/LoadingButton";
import { useNavigate } from "react-router-dom";
import { getCoaches } from "../../redux/network/coachAPI";

import { handleOtpError, handleRegisterError } from '../../config/errors'
import { useDispatch } from "react-redux";
import { setIsLoginDialog } from "../../redux/reducers/loginSlice";
import { SERVER_MESSAGE } from "../../config/settings";
import { setAlertMessage, setAlertType } from "../../redux/reducers/alertSlice";

const steps = [
  { heading: "Before we start, can we get your name?", placeholder: "Your full Name", name: "fullname", error: "Please your enter name" },
  { heading: "Email", placeholder: "exa@abc.com", name: "email", error: "Please enter your email" },
  { heading: "DOB", placeholder: "DD-MM-YYYY", name: "dob", error: "Please enter your date of birth" },
  { heading: "Weight", placeholder: "Weight in kg", name: "weight", error: "Please enter your weight" },
  { heading: "Height", placeholder: "Height in cm", name: "height", error: "Please enter your height" },
  { heading: "Gender", placeholder: "Gender", name: "gender", error: "Please enter your gender" },
  { heading: "Who is the Head Coach of your Academy?", placeholder: "Who is the Head Coach of your Academy?", name: "coachName", error: "Please enter your head coach name" },
  { heading: "Phone number", placeholder: "Phone number", name: "phoneNumber", error: "Phone number" },
  { heading: "Otp for registration?", placeholder: "Otp", name: "otp", error: "Please enter otp" },
];

const SignupPage = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [activeStep, setActiveStep] = useState(0);
  const [stepCount, setStepCount] = useState(steps.length);
  const [formData, setFormData] = useState(Array(stepCount).fill(""));
  const [selectedGender, setSelectedGender] = useState("");
  const [errorMsg, setErrorMsg] = useState('')
  const [loading, setloading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false);
  const [alertMsg, setAlertMsg] = useState("")
  const [resendDisabled, setResendDisabled] = useState(false);
  const [timer, setTimer] = useState(60);

  const jwtToken = localStorage.getItem("BQS_TOKEN");

  useEffect(() => {
    if (jwtToken) {
      navigate("/")
    }
  }, [jwtToken])




  console.log(formData)
  // const [isBackButtonClicked, setBackbuttonPress] = useState(false);
  // Thanks for Signing Up
  // const onBackButtonEvent = (e) => {
  //   e.preventDefault();
  //   if (!isBackButtonClicked) {
  //     const confirmed = window.confirm("Do you want to exist sign up process?");
  //     if (confirmed) {
  //       setBackbuttonPress(true);
  //       window.location.href = '/';
  //     } else {
  //       window.history.pushState(null, null, window.location.pathname);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   window.history.pushState(null, null, window.location.pathname);
  //   window.addEventListener('popstate', onBackButtonEvent);
  //   window.onbeforeunload = function () {
  //     return "Data will be lost if you leave the page, are you sure?";
  //   };

  //   return () => {
  //     window.removeEventListener('popstate', onBackButtonEvent);
  //   };
  // }, []);

  const BQS_ID = localStorage.getItem("SIGN_UP_BQS__ID")
  // console.log(BQS_ID)

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(setIsLoginDialog(false))
  }, []);


  let intervalId;
  //resend timer
  useEffect(() => {
    if (resendDisabled) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer === 0) {
            clearInterval(intervalId);
            setResendDisabled(false);
            return 60;
          } else {
            return prevTimer - 1;
          }
        });
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [resendDisabled]);



  // useEffect(() => {
  //   if (!BQS_ID) {
  //     navigate("/")
  //     localStorage.removeItem("SIGN_UP_BQS__ID")
  //   }
  // }, [BQS_ID])


  //Redirection after some 3 sec when user registered successfully
  useEffect(() => {
    if (isSuccess) {
      const redirectTimeout = setTimeout(() => {
        navigate('/upload');
        localStorage.removeItem("SIGN_UP_BQS__ID")
        window.scrollTo(0, 0);
      }, 1000);
      return () => clearTimeout(redirectTimeout);
    }
  }, [isSuccess]);


  //Handle Next
  const handleNext = async () => {
    setErrorMsg("");
    const formValue = formData[activeStep];
    if (formValue) {
      if (activeStep === 1) {
        // Check if the form value is a valid email address
        if (!/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(formValue)) {
          // console.log("Email is invalid");
          setErrorMsg("Please enter a valid email address");
        } else {
          if (activeStep < stepCount - 1) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          }
        }
      } else if (activeStep === 2) {
        if (/^\d{2}\-\d{2}\-\d{4}$/.test(formValue)) {
          const parts = formValue.split('-');
          const enteredDate = new Date(parts[2], parts[1] - 1, parts[0]); // Note: Month is 0-based
          const today = new Date();
          today.setHours(0, 0, 0, 0); // Set time to midnight for comparison
          console.log(enteredDate)
          // Compare the entered date with today's date
          if (enteredDate > today) {
            setErrorMsg("Date cannot be greater than today");
          } else {
            if (activeStep < stepCount - 1) {
              setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
          }
        } else {
          setErrorMsg("Please enter a valid date in DD-MM-YYYY format");
        }

      }
      else {
        if (activeStep < stepCount - 1) {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
      }
    } else {
      if (activeStep === 6) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else {
        setErrorMsg(steps[activeStep]["error"]);
      }

    }
  };

  //SEND OTP HANDLER
  const handleSendOTP = () => {
    const phoneNumber = formData[7]

    setErrorMsg('');
    //Check for empty phone number
    if (!phoneNumber) {
      setErrorMsg("Please enter phone number")
      return;
    }

    //check if  phone number is valid
    if (!/^\d{10}$/.test(phoneNumber)) {
      setErrorMsg("Please enter valid phone number")
      return;
    }
    // Login OTP API Call:
    setloading(true);
    var obj = {
      "type": 'registration',
      "phone": {
        "cc": "+91",
        "number": phoneNumber
      },
      "role": "player"

    }
    console.log(obj)
    var apiUrl = new URL(POST_LOGIN_OTP);
    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(obj)
    }).then(res => {
      console.log("res", res)
      return res.json()
    }).then(response => {
      console.log("LOGIN-OTP RESPONSE: ", response);
      let errorMsg = SERVER_MESSAGE ? response.message : handleOtpError(response)

      console.log(errorMsg)
      if (response && 'code' in response && response['code'] == 200) {
        // setSignUpBQSId(response?.data?._id)
        localStorage.setItem("SIGN_UP_BQS__ID", response?.data?._id)
        setErrorMsg(errorMsg || "OTP has been sent");
        setResendDisabled(true);

        //toast msg
        dispatch(setAlertMessage(errorMsg))
        dispatch(setAlertType("success"))
        //Calling handle next function
        handleNext()
      } else {
        //toast msg
        // dispatch(setAlertMessage(errorMsg))
        // dispatch(setAlertType("error"))
        throw new Error(errorMsg || "Something went wrong");
      }
    }).catch(error => {
      console.log("CATCH error", error)
      setErrorMsg(error.message || "something went wrong");
      // dispatch(setAlertMessage(error.message || "something went wrong"))
      // dispatch(setAlertType("error"))
    }).finally(() => setloading(false));
  };

  const handleResendOTP = () => {
    //calling handleSendOTP method
    handleSendOTP()
    setTimer(60);
    setResendDisabled(true);
    // //to show alert msg
    // alertMsg(`OTP has been resent successfully`)
    // setIsSuccess(true)
  };


  const handleExit = () => {
    const confirmed = window.confirm("Do you want to exit the sign-up process?");
    if (confirmed) {
      // Prevent navigating back if the user cancels the prompt
      localStorage.removeItem("SIGN_UP_BQS__ID")
      navigate("/") // Revert the URL change
      return;
    } else {
      return;
    }
  };

  const handleBack = () => {
    setErrorMsg("")
    if (activeStep > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };


  console.log("name", formData[0].split(" "))

  const handleInputChange = (index, event) => {
    setErrorMsg("")
    let value = event.target.value
    // console.log(event.target.name, value)
    if (event.target.name === "otp") {
      if ((/^\d*$/.test(value))) {
        if (value.length <= 6) {
          const newData = [...formData];
          newData[index] = value;
          setFormData(newData);
        }
      } else {
        setErrorMsg("Please enter number only")
      }
    } else if (event.target.name === "phoneNumber") {
      if ((/^\d*$/.test(value)) && value.length <= 10) {
        const newData = [...formData];
        newData[index] = value;
        setFormData(newData);
      } else {
        setErrorMsg("Please enter number only")
      }
    } else if (event.target.name === "weight") {
      if (/^\d*\.?\d*$/.test(value)) {
        const newData = [...formData];
        newData[index] = value;
        setFormData(newData);
      } else {
        setErrorMsg("Please enter number only")
      }
    } else if (event.target.name === "height") {
      if (/^\d*\.?\d*$/.test(value)) {
        const newData = [...formData];
        newData[index] = value;
        setFormData(newData);
      } else {
        setErrorMsg("Please enter number only")
      }
    } else if (event.target.name === "dob") {
      if (value === "") {
        // Clear error message and update form data
        setErrorMsg("");
        const newData = [...formData];
        newData[index] = value;
        setFormData(newData);
      } else {
        let formattedDate;
        if (value.length < formData[index].length) {
          // Handle backspace when length is decreasing
          const newData = [...formData];
          newData[index] = value;
          setFormData(newData);
        } else if (value.length === 2) {
          // Day validation
          if (parseInt(value) > 31) {
            setErrorMsg("Please enter a correct Day");
          } else {
            formattedDate = value + "-";
            const newData = [...formData];
            newData[index] = formattedDate;
            setFormData(newData);
          }
        } else if (value.length === 5) {
          // Month validation
          if (value.slice(3) > 12) {
            setErrorMsg("Please enter a correct Month");
          } else {
            formattedDate = value + "-";
            const newData = [...formData];
            newData[index] = formattedDate;
            setFormData(newData);
          }
        } else if (value.length === 11) {
          // Year validation
          const year = parseInt(value.slice(6));
          const currentYear = new Date().getFullYear();
          if (year > currentYear) {
            setErrorMsg("Please enter a correct Year");
          }
        } else {
          const newData = [...formData];
          newData[index] = value;
          setFormData(newData);
        }
      }
    }
    else {
      const newData = [...formData];
      newData[index] = value;
      setFormData(newData);
    }
  };

  const handleSelectGender = (gender) => {
    setErrorMsg("")
    setSelectedGender(gender);
    const newData = [...formData];
    newData[activeStep] = gender;
    setFormData(newData);
  };



  const handleSubmit = (event) => {
    event.preventDefault()
    //checking head coach validation before submitting (optional)
    // if (!formData[7]) {
    //   setErrorMsg("Please enter your head coach name")
    //   return;
    // }

    //Diving full name in firstName , Middlename and Lastname

    const fullnameArr = formData[0].trim().split(" ")

    let firstName = "";
    let middleName = "";
    let lastName = "";

    if (fullnameArr.length === 1) {
      firstName = fullnameArr[0];
    } else if (fullnameArr.length === 2) {
      firstName = fullnameArr[0];
      lastName = fullnameArr[1];
    } else if (fullnameArr.length === 3) {
      firstName = fullnameArr[0];
      middleName = fullnameArr[1];
      lastName = fullnameArr[2];
    } else {
      firstName = fullnameArr[0];
      middleName = fullnameArr[1];
      lastName = fullnameArr.slice(2).join(" ");
    }



    // Construct the name object conditionally
    const name = {};
    if (middleName) name.middleName = middleName;
    if (lastName) name.lastName = lastName;


    setloading(true)
    setIsSuccess(false)

    var obj = {
      "otpToken": {
        "type": "registration",
        "otp": formData[8]
      },
      "profileData": {
        "name": {
          firstName: firstName,
          ...name
        },
        "dob": formData[2],
        "gender": formData[5],
        "weight": formData[3],
        "height": formData[4],
        "gamingProfile": {
          "coachName": formData[6]
        }
      },
      "email": formData[1],
    }
    const url = POST_REGISTER_LOGIN + "/" + BQS_ID
    console.log("url", url)

    var apiUrl = new URL(url);
    fetch(apiUrl, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(obj)
    }).then(res => {
      return res.json();
    }).then(response => {
      console.log("SIGN UP  POST RESPONSE: ", response);
      const regErrorMessage = handleRegisterError(response)
      if (response && 'code' in response && response['code'] == 200) {
        setIsSuccess(true)
        setAlertMsg(regErrorMessage || "Sign Up successful. Welcome!")
        localStorage.setItem('BQS__ID', response.data["_id"]);
        localStorage.setItem('BQS_TOKEN', response.data.token.token);
        localStorage.setItem('BQS_ROLE', response.data.role);
        localStorage.setItem('BQS_USER_NAME', response.data.profileData.name.firstName);

        navigate('/upload');

      } else {
        setErrorMsg(regErrorMessage || "Something went wrong ! try again later")
      }
    }).catch(error => {
      console.error("Exception Login API: ", error);
      setErrorMsg(error.message || "Something went wrong ! try again later")
    }).finally(() => setloading(false));
  };



  return (
    <>
      <section className="signup-container">
        <div className="contianer">

          {isSuccess && alertMsg && (
            <Alert severity="success">
              {alertMsg}
            </Alert>
          )}

          <div className="button-container">
            <div>
              {activeStep !== 0 && <button onClick={handleBack}><i className="fa fa-arrow-left"></i>Previous</button>}
            </div>
            <div>
              <button onClick={handleExit} className="exit">Exit</button>
            </div>
          </div>
          <div className="progress-bar-container">
            <div
              className="progress-bar"
              style={{ width: `${(activeStep / (stepCount - 1)) * 100}%` }}
            >
              {Array.from({ length: stepCount }, (_, index) => (
                <span
                  key={index}
                  className={`step ${index <= activeStep ? "active" : ""}`}
                ></span>
              ))}
            </div>
          </div>

          <div className="step-content">
            <h5 className="heading" >{steps[activeStep].heading}</h5>

            {activeStep === 5 ? (
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <button
                    className={`gender-box ${selectedGender === "male" ? "selected" : ""
                      }`}
                    onClick={() => handleSelectGender("male")}
                  >
                    Male
                  </button>
                </Grid>
                <Grid item xs={4}>
                  <button
                    className={`gender-box ${selectedGender === "female" ? "selected" : ""
                      }`}
                    onClick={() => handleSelectGender("female")}
                  >
                    Female
                  </button>
                </Grid>
                <Grid item xs={4}>
                  <button
                    className={`gender-box ${selectedGender === "other" ? "selected" : ""
                      }`}
                    onClick={() => handleSelectGender("other")}
                  >
                    Other
                  </button>
                </Grid>
              </Grid>
            ) : (
              <TextField
                placeholder={steps[activeStep].placeholder}
                value={formData[activeStep]}
                name={steps[activeStep].name}
                onChange={(e) => handleInputChange(activeStep, e)}
                className="textfield"
                fullWidth
                margin="normal"
                variant="standard"
                sx={{
                  outline: "none",
                  color: "#E0E1E6",
                  '& .MuiInput-underline:before': {
                    borderBottom: '2px solid #247E45',
                  },
                  '& .MuiInput-underline:after': {
                    borderBottom: 'none',
                  },
                  '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                    borderBottom: '2px solid #247E45',
                  },
                  '& .MuiInputBase-input': {
                    color: 'Black',
                    fontSize: {
                      xs: '14px', // font size for small devices
                      sm: '18px', // font size for tablets
                      md: '20px', // font size for small laptops
                      lg: '24px', // font size for desktops
                    },
                    padding: "1rem"
                  },
                }}

              />
            )}
          </div>
          <p className="error">{errorMsg}</p>
          <div className="button-bottom">
            <div>
              {activeStep === stepCount - 1 ? (
                <div className="d-flex justify-content-center align-items-center">
                  <button
                    className="submit-button"
                    type="button"
                    onClick={handleSubmit}
                    disabled={isSuccess}
                  >
                    {loading ? <LoadingButton /> : isSuccess ? "Thanks for Signing Up" : "CREATE ACCOUNT"}
                  </button>
                  <p className="resend_otp">
                    {resendDisabled ? (
                      `Resend OTP in ${timer} seconds`
                    ) : (
                      <a href="#" onClick={handleResendOTP}>
                        Resend OTP
                      </a>
                    )}
                  </p>
                </div>

              ) : activeStep === 7 ? (
                <button
                  className="next-button"
                  onClick={handleSendOTP}
                >
                  {loading ? <LoadingButton /> : "SEND OTP"}
                </button>
              ) : (
                <button
                  className="next-button"
                  onClick={handleNext}
                >
                  Next
                  <i className="fa fa-arrow-right"></i>
                </button>
              )

              }
            </div>
          </div>


        </div>
      </section >
    </>
  );
};

export default SignupPage;
