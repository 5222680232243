import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
    name: `user`,
    initialState: {
        profile: {
            isStarted: false,
            isError: false,
            error: '',
            value: {},
        },
        updateProfile: {
            isStarted: false,
            isError: false,
            error: '',
            value: {},
        },
        profilePic: {
            isProfileStarted: false,
            isProfilePicError: false,
            profilePicError: '',
            profilePicUrl: '',
        },

        tempUserData: {
            value: {}
        },
        allUsers: {
            isStarted: false,
            isError: false,
            error: '',
            value: [],
            pagination: {},
            hubSlug: {},
            phase: ''
        },
        addUser: {
            isStarted: false,
            isError: false,
            error: '',
            value: '',
        },
        editUser: {
            isStarted: false,
            isError: false,
            error: '',
            value: '',
        },
        removeUser: {
            isStarted: false,
            isError: false,
            error: '',
            value: '',
        },
    },
    reducers: {
        set_TempUserData: (state, action) => {
            let { payload } = action;
            state.tempUserData.value = payload;
        },
        isStarted_GetProfile: (state) => {
            state.profile.isStarted = true;
            state.profile.isError = false;
        },
        success_GetProfile: (state, action) => {
            // console.log('GET-PROFILE: ', action.payload)
            let { payload } = action;
            state.profile.isError = false;
            state.profile.isStarted = false;
            state.profile.error = '';
            state.profile.value = payload;
        },
        error_GetProfile: (state, action) => {
            // console.log(action)
            let { payload } = action;
            state.profile.isError = true;
            state.profile.isStarted = false;
            state.profile.error = payload;
            state.profile.value = [];
        },


        isStarted_UpdateProfile: (state) => {
            state.updateProfile.isStarted = true;
            state.updateProfile.isError = false;
        },
        success_UpdateProfile: (state, action) => {
            // console.log('GET-PROFILE: ', action.payload)
            let { payload } = action;
            state.updateProfile.isError = false;
            state.updateProfile.isStarted = false;
            state.updateProfile.error = '';
            state.updateProfile.value = payload;
        },
        error_UpdateProfile: (state, action) => {
            // console.log(action)
            let { payload } = action;
            state.updateProfile.isError = true;
            state.updateProfile.isStarted = false;
            state.updateProfile.error = payload;
            state.updateProfile.value = [];
        },

        isStarted_GetProfilePic: (state) => {
            // console.log('GET-PROFILE-PIC Started: ')
            state.profilePic.isProfileStarted = true;
            state.profilePic.isProfilePicError = false;
        },
        success_GetProfilePic: (state, action) => {
            // console.log('GET-PROFILE-PIC success: ', action.payload)
            let { payload } = action;
            state.profilePic.isProfilePicError = false;
            state.profilePic.isProfileStarted = false;
            state.profilePic.profilePicError = '';
            state.profilePic.profilePicUrl = payload;
        },
        error_GetProfilePic: (state, action) => {
            // console.log('GET-PROFILE-PIC error: ', action.payload)
            let { payload } = action;
            state.profilePic.isProfilePicError = true;
            state.profilePic.isProfileStarted = false;
            state.profilePic.profilePicUrl = payload;
        },

        isStarted_PutProfilePic: (state) => {
            console.log('Put-PROFILE-PIC Started: ')
            state.profilePic.isProfileStarted = true;
            state.profilePic.isProfilePicError = false;
        },
        success_PutProfilePic: (state, action) => {
            console.log('PUT-PROFILE-PIC success: ', action.payload)
            let { payload } = action;
            state.profilePic.isProfileStarted = false;
            state.profilePic.isProfilePicError = false;
            state.profilePic.profilePicError = '';
            state.profilePic.profilePicUrl = payload;
        },
        error_PutProfilePic: (state, action) => {
            console.log('PUT-PROFILE-PIC error: ', action.payload)
            let { payload } = action;
            state.profilePic.isProfileStarted = false;
            state.profilePic.isProfilePicError = true;
            state.profilePic.profilePicError = payload;
        },

        isStarted_GetAllUsers: (state) => {
            state.allUsers.isStarted = true;
            state.allUsers.isError = false;
        },
        success_GetAllUsers: (state, action) => {
            let { payload, pagination, phase } = action.payload;
            state.allUsers.pagination = pagination
            state.allUsers.isError = false;
            state.allUsers.isStarted = false;
            state.allUsers.error = '';
            state.allUsers.value = payload;
        },
        error_GetAllUsers: (state, action) => {
            let { payload } = action;
            state.allUsers.isError = true;
            state.allUsers.isStarted = false;
            state.allUsers.error = payload;
            state.allUsers.value = [];
        },
        isStarted_PostUser: (state) => {
            state.addUser.isStarted = true;
            state.addUser.isError = false;
        },
        reset_PostUser: (state) => {
            state.addUser.isStarted = false;
            state.addUser.isError = false;
            state.addUser.value = '';
            state.addUser.error = '';
        },
        success_PostUser: (state, action) => {
            let { payload } = action;
            state.addUser.isError = false;
            state.addUser.isStarted = false;
            state.addUser.error = '';
            state.addUser.value = payload;
        },
        error_PostUser: (state, action) => {
            let { payload } = action;
            state.addUser.isError = true;
            state.addUser.isStarted = false;
            state.addUser.error = payload;
            state.addUser.value = '';
        },
        //--
        isStarted_PutUser: (state) => {
            state.editUser.isStarted = true;
            state.editUser.isError = false;
        },
        reset_PutUser: (state) => {
            state.editUser.isStarted = false;
            state.editUser.isError = false;
            state.editUser.value = '';
            state.editUser.error = '';
        },
        success_PutUser: (state, action) => {
            let { payload } = action;
            state.editUser.isError = false;
            state.editUser.isStarted = false;
            state.editUser.error = '';
            state.editUser.value = payload;
        },
        error_PutUser: (state, action) => {
            let { payload } = action;
            state.editUser.isError = true;
            state.editUser.isStarted = false;
            state.editUser.error = payload;
            state.editUser.value = '';
        },
        //--
        isStarted_DeleteUser: (state) => {
            state.removeUser.isStarted = true;
            state.removeUser.isError = false;
        },
        reset_DeleteUser: (state) => {
            state.removeUser.isStarted = false;
            state.removeUser.isError = false;
            state.removeUser.value = '';
            state.removeUser.error = '';
        },
        success_DeleteUser: (state, action) => {
            let { payload } = action;
            state.removeUser.isError = false;
            state.removeUser.isStarted = false;
            state.removeUser.error = '';
            state.removeUser.value = payload;
        },
        error_DeleteUser: (state, action) => {
            let { payload } = action;
            state.removeUser.isError = true;
            state.removeUser.isStarted = false;
            state.removeUser.error = payload;
            state.removeUser.value = '';
        },
    }
})

export const {
    isStarted_GetAllUsers, success_GetAllUsers, error_GetAllUsers,
    isStarted_GetProfile, success_GetProfile, error_GetProfile,
    isStarted_PostUser, success_PostUser, error_PostUser, reset_PostUser,
    isStarted_PutUser, success_PutUser, error_PutUser, reset_PutUser,
    isStarted_DeleteUser, success_DeleteUser, error_DeleteUser, reset_DeleteUser,
    set_TempUserData,
    isStarted_GetProfilePic, success_GetProfilePic, error_GetProfilePic,
    isStarted_UpdateProfile, success_UpdateProfile, error_UpdateProfile
} = userSlice.actions
export default userSlice.reducer