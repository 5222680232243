import { createSlice } from "@reduxjs/toolkit";

export const videoSlice = createSlice({
    name: "video",
    initialState: {
        video: {
            isStarted: false,
            isError: false,
            error: '',
            data: [],
        },
        videoContent: {
            isStartedvideoContent: false,
            isvideoContentError: false,
            videoContentError: '',
            videoContentUrl: '',
        },
        videosCount: {
            isStarted: false,
            isError: false,
            error: '',
            data: '',
        }
    },
    reducers: {
        isStarted_GetVideo: (state) => {
            state.video.isStarted = true;
            state.video.isError = false;
        },
        success_GetVideo: (state, action) => {
            console.log('GET-Video: ', action.payload)
            let { payload } = action;
            state.video.isStarted = false;
            state.video.isError = false;
            state.video.error = '';
            state.video.data = payload;
        },
        error_GetVideo: (state, action) => {
            console.log(action)
            let { payload } = action;
            state.video.isStarted = false;
            state.video.isError = true;
            state.video.error = payload;
        },
        isStarted_GetFetchVideoContent: (state) => {
            state.videoContent.isStartedvideoContent = true;
            state.videoContent.isvideoContentError = false;
        },
        success_GetFetchVideoContent: (state, action) => {
            // console.log('GET-Video: ', action.payload)
            let { payload } = action;
            state.videoContent.isStartedvideoContent = false;
            state.videoContent.isvideoContentError = false;
            state.videoContent.videoContentError = '';
            state.videoContent.videoContentUrl = payload;
        },
        error_GetFetchVideoContent: (state, action) => {
            console.log(action)
            let { payload } = action;
            state.videoContent.isStartedvideoContent = false;
            state.videoContent.isvideoContentError = true;
            state.videoContent.videoContentError = payload;
        },

        isStarted_GetVideosCount: (state) => {
            state.videosCount.isStarted = true;
            state.videosCount.isError = false;
        },
        success_GetVideosCount: (state, action) => {
            console.log('GET-Video: ', action.payload)
            let { payload } = action;
            state.videosCount.isStarted = false;
            state.videosCount.isError = false;
            state.videosCount.error = '';
            state.videosCount.data = payload;
        },
        error_GetVideosCount: (state, action) => {
            console.log(action)
            let { payload } = action;
            state.videosCount.isStarted = false;
            state.videosCount.isError = true;
            state.videosCount.error = payload;
        },

    }
})

export const { isStarted_GetVideo, success_GetVideo, error_GetVideo, isStarted_GetFetchVideoContent, success_GetFetchVideoContent, error_GetFetchVideoContent, isStarted_GetVideosCount, success_GetVideosCount, error_GetVideosCount } = videoSlice.actions

export default videoSlice.reducer;

