import React from 'react';
import { BrowserRouter, Routes, Route, useLocation, useParams, useNavigate, Navigate } from 'react-router-dom';
import Home from '../Components/Home/Home';
import Nav from '../Components/Nav/Nav';
// import Upload from '../Components/Upload/Upload';
import Video from '../Components/MyJournal/Video';
import SignupPage from '../Components/Signup/SingupPage';
import Footer from '../Components/footer/Footer';
import Profile from '../Components/Profile/Profile';
import Blogs from '../Components/Blogs/Blogs'
import Upload from '../Components/upload/Upload';
import BlogDetails from "../Components/BlogDetails/BlogDetails"
import ChoosePlan from '../Components/ChoosePlan/ChoosePlan';
import Greetings from '../Components/Greetings/Greetings';
import PrivacyPolicy from '../Components/PrivacyPolicy/PrivacyPolicy'
import TermsService from '../Components/TermService/TermsService';
import About from '../Components/About_Us/About';
import ReportOutlet from '../Components/Reports/ReportOutlet/ReportOutlet';
import ReportContent from '../Components/Reports/ReportContent/ReportContent';
import YourApproach from '../Components/Reports/ReportApproach/YourApproach';
import CorrectApproach from '../Components/Reports/ReportApproach/CorrectApproach';
import ProfileOutlet from '../Components/Profile/ProfileOutlet/ProfileOutlet'
import PersonalDetails from '../Components/Profile/PersonalDetails/PersonalDetails'
import Membership from '../Components/Profile/Membership/Membership'
import ProfileReport from '../Components/Profile/ProfileReport/ProfileReport'
import Support from '../Components/Profile/Support/Support'



export default function Routers() {



  return (
    <BrowserRouter>
      <Nav />
      <Routes>
        <Route index element={<Home />} />
        <Route path="blogs" element={<Blogs />} />
        <Route path="blogdetails/:blogId" element={<BlogDetails />} />
        <Route path="upload" element={<Upload />} />
        <Route path="choose_plan" element={<ChoosePlan />} />
        <Route path="my_Journal" element={<Video />} />
        <Route path="signup" element={<SignupPage />} />
        {/* <Route path="profile" element={<Profile />} /> */}
        <Route path="profile" element={<ProfileOutlet />}>
          <Route index element={<PersonalDetails />} />
          <Route path='personal_details' element={<PersonalDetails />} />
          <Route path='membership' element={<Membership />} />
          <Route path='report' element={<ProfileReport />} />
          <Route path='support' element={<Support />} />
          <Route />
        </Route>
        <Route path='greetings' element={<Greetings />} />
        <Route path="privacy_policy" element={<PrivacyPolicy />} />
        <Route path="term_service" element={<TermsService />} />
        <Route path="about" element={<About />} />
        <Route path="reports/:reviewPostId" element={<ReportOutlet />}>
          <Route index element={<ReportContent />} />
          <Route path="uploaded-video" element={<ReportContent />} />
          <Route path=":technique/your-approach" element={<YourApproach />} />
          <Route path=":technique/correct-approach" element={<CorrectApproach />} />
        </Route>
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}
