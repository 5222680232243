import { LOGIN_REDIRECT, GET_USER_INFO, POST_USER_LOGOUT, POST_REFRESH_TOKEN, POST_UPDATE_USER_PIC, GET_USER_PIC, PUT_UPDATE_USER_INFO, BUY_CREDIT } from '../../config/endpoints';
import { setAlertMessage, setAlertType } from '../reducers/alertSlice';
import { setIsLoginDialog } from '../reducers/loginSlice';

// import {
//     LOGIN_REDIRECT, GET_ALL_USERS_EP, POST_USER_EP, DELETE_USER_EP, PUT_USER_EP,
//     GW_PROFILE_EP, GET_USER_INFO
// } from '../../config/endpoints';

import * as USER_ACTION from "../reducers/userSlice";


export const getUserProfile = (jwtToken) => {
    console.log("HITTING - getUserProfile", jwtToken);
    return dispatch => {
        dispatch(USER_ACTION.isStarted_GetProfile());
        var apiUrl = new URL(GET_USER_INFO);
        fetch(apiUrl, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${jwtToken}`
            },
        })
            .then(res => {
                // console.log("get profile response", res)
                if (!res.ok) {
                    if (res.status === 401) {
                        // Unauthorized
                        window.location = LOGIN_REDIRECT;
                        dispatch(setIsLoginDialog(true))
                        localStorage.clear()
                    } else {
                        throw new Error(res.statusText);
                    }
                }
                return res.json();
            })
            .then(response => {
                // console.log("get profile response res.json(", response)
                if (!response || !response.hasOwnProperty('code') || response.code !== 200) {
                    throw new Error('Server error: ' + response.message);
                }
                dispatch(USER_ACTION.success_GetProfile(response.data));
            })
            .catch(error => {
                // console.error(error.message);
                dispatch(USER_ACTION.error_GetProfile(error.message));
            });
    }
}

export const UpdateUserProfile = (jwtToken, formData) => {
    // console.log("HITTING - UpdateUserProfile", jwtToken, formData);
    return dispatch => {
        dispatch(USER_ACTION.isStarted_UpdateProfile());
        var apiUrl = new URL(PUT_UPDATE_USER_INFO);
        fetch(apiUrl, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${jwtToken}`,
                "Content-Type": "Application/json"
            },
            body: JSON.stringify(formData)
        }).then(res => {
            // console.log(res)
            if (!res.ok) {
                if (res.status === 401) {
                    // Unauthorized
                    window.location = LOGIN_REDIRECT;
                    dispatch(setIsLoginDialog(true))
                    localStorage.clear()
                } else {
                    throw new Error(res.statusText);
                }
            }
            return res.json();
        }).then(response => {
            // console.log(response)
            if (response.code === 200) {
                // dispatch(setAlertMessage(response.message))
                // dispatch(setAlertType("success"))
                // dispatch(getUserProfile(jwtToken))
                dispatch(USER_ACTION.success_GetProfile(response.data));
            } else {
                dispatch(setAlertMessage(response.message))
                dispatch(setAlertType("error"))
                // dispatch(getUserProfilePic(jwtToken))
            }

        }).catch(error => {
            dispatch(USER_ACTION.error_GetProfilePic(error.message));
            dispatch(setAlertType("error"))
        });
    }
}

export const getUserProfilePic = (jwtToken) => {
    // console.log("HITTING - getUserProfilePic", jwtToken);
    return dispatch => {
        dispatch(USER_ACTION.isStarted_GetProfilePic());
        var apiUrl = new URL(GET_USER_PIC);
        fetch(apiUrl, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${jwtToken}`,
                'Content-Type': 'image/jpeg'
            },
        })
            .then(res => {
                console.log("response- getUserProfilePic", res);
                if (!res.ok) {
                    if (res.status === 401) {
                        // Unauthorized
                        window.location = LOGIN_REDIRECT;
                        dispatch(setIsLoginDialog(true))
                        localStorage.clear()

                    } else {
                        throw new Error(res.statusText);

                    }
                }

                return res.blob()
            })
            .then(blob => {
                // console.log(blob)

                if (blob.size === 0) {
                    throw new Error('Empty response received');
                }
                const file = new File([blob], 'image', { type: "image/jpeg" })
                const fr = new FileReader()
                fr.readAsDataURL(file)
                fr.addEventListener("load", () => {
                    const res = fr.result
                    // console.log(res)
                    dispatch(USER_ACTION.success_GetProfilePic(res));
                })
                // const imageObjectURL = URL.createObjectURL(blob);
                // console.log(imageObjectURL)
                // dispatch(USER_ACTION.success_GetProfilePic(imageObjectURL));
            })
            .catch(error => {
                console.error(error.message);
                dispatch(USER_ACTION.error_GetProfilePic(error.message));
            });
    }
}

export const UpdateUserProfilePic = (jwtToken, file) => {
    console.log("HITTING - UpdateUserProfilePic", jwtToken);
    return dispatch => {
        dispatch(USER_ACTION.isStarted_GetProfilePic());
        var apiUrl = new URL(POST_UPDATE_USER_PIC);
        fetch(apiUrl, {
            method: "POST",
            headers: {
                "Authorization": "Bearer " + jwtToken,
                filename: file.name,
                streamsize: file.size.toString(),
            },
            body: file
        }).then(res => {
            console.log(res)
            if (!res.ok) {
                if (res.status === 401) {
                    // Unauthorized
                    window.location = LOGIN_REDIRECT;
                    dispatch(setIsLoginDialog(true))
                    localStorage.clear()
                    return
                } else {
                    throw new Error(res.statusText);
                }
            }

            return res.json();
        }).then(response => {
            console.log(response)
            if (response.code === 200) {
                // dispatch(setAlertMessage(response.message))
                // dispatch(setAlertType("success"))
                dispatch(getUserProfilePic(jwtToken))
            } else {
                dispatch(setAlertMessage(response.message))
                dispatch(setAlertType("error"))
                dispatch(getUserProfilePic(jwtToken))
            }

        }).catch(error => {
            console.error(error.message);
            // dispatch(USER_ACTION.error_GetProfilePic(error.message));
        });
    }
}

export const userLogout = (jwtToken) => {
    var apiUrl = new URL(POST_USER_LOGOUT);
    const logoutResponse = fetch(apiUrl, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${jwtToken}`
        },
        // body: JSON.stringify(obj) //Empty body
    })
    return logoutResponse
}


export const refreshToken = (jwtToken) => {
    var apiUrl = new URL(POST_REFRESH_TOKEN);
    const refreshTokenResponse = fetch(apiUrl, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${jwtToken}`
        },
        // body: JSON.stringify(obj) //Empty body
    })
    return refreshTokenResponse
}


export const buyReviewCredits = async (jwtToken) => {
    try {
        const url = `${BUY_CREDIT}`;
        const apiUrl = new URL(url);
        const response = await fetch(apiUrl, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${jwtToken}`,
            },
        });

        if (response.status === 401) {
            localStorage.clear();
            return { code: 401, message: "Unauthorized" };
        }

        const result = await response.json();
        return result;

    } catch (error) {
        console.error('buyReviewCredits error:', error);
        return { code: 500, message: "Internal Server Error" };
    }
};

