import React from 'react'
import './ProfileOutlet.scss'
import ProfileSidebar from '../ProfileSidebar/ProfileSidebar'
import { Outlet } from 'react-router-dom'

const ProfileOutlet = () => {
    return (
        <div className='profileoutlet_container'>
            <ProfileSidebar />
            <Outlet />
        </div>
    )
}

export default ProfileOutlet