import React, { useEffect } from 'react'
import "./Greetings.scss"
import { Link, useNavigate } from 'react-router-dom'

export default function Greetings() {
    const navigate = useNavigate()
    const handleBack = () => {
        navigate("/")
    }
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div>
            <div className='container'>

                <section className='greetings'>
                    <p>Thank you for submitting your video. Your report is currently being
                        prepared and will be sent to you via email and will be available in your{" "}
                        <span><Link to="/my_Journal">My Journal</Link></span> section within 48 hours.
                    </p>
                    <button onClick={() => handleBack()}>Back to home page <i className="fa-solid fa-arrow-right icon"></i></button>
                </section>
            </div>
        </div>
    )
}
