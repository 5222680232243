import React, { useEffect } from 'react'
import './About.scss'

function About() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='About_us'>
            <div className='bannar'>
                <div className='container'>
                    <h1 className='heading'>About Us</h1>
                </div>
            </div>
            <div className='About_us_content'>
                <div className='container'>
                    <div className='top_section'>
                        <p> At <a href='Visist.ai' target='_blank'>Visist.ai</a>, we are on a mission to democratize the badminton experience for players at all  levels. Our innovative platform harnesses the power of mobile AI to provide elite-level video  analysis solutions that are accessible and affordable. By transforming standard smartphones  into advanced coaching tools, we enable players to receive feedback in the language of a  coach</p>
                        <p>With<a href='Visist.ai' target='_blank'>Visist.ai</a>, athletes around the world can track, share, and refine their game, experiencing  the advancements typically available to the elite.</p>
                    </div>
                    <div className='bottom_section'>
                        <div className='queote_container'>
                            <p className='double_queote rotote_queote'>"</p>
                            <p className='queote'>If we dare to win, we<br />should also dare to lose.</p>
                            <div>
                                <p className='name'>- Lee Chong Wei</p>
                                <p className='double_queote flex_end'>"</p>
                            </div>
                        </div>
                        <img src='assets/About_us.avif' alt='about_us' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About