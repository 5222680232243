import { createSlice } from "@reduxjs/toolkit";

export const alertSlice = createSlice({
    name: "alert",
    initialState: {
        alertTime: 5000,
        alertMessage: "",
        alertType: ""
    },
    reducers: {
        setAlertTime(state, action) {
            state.alertTime = action.payload;
        },
        setAlertMessage(state, action) {
            state.alertMessage = action.payload;
        },
        setAlertType(state, action) {
            state.alertType = action.payload;
        }
    }
})

export const { setAlertTime, setAlertMessage, setAlertType } = alertSlice.actions

export default alertSlice.reducer