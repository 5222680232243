import { createSlice } from '@reduxjs/toolkit'

export const reportSlice = createSlice({
    name: `report`,
    initialState: {
        postsList: {
            isStarted: false,
            isError: false,
            error: '',
            value: {},
            techniques: {},
            TechniquesWithFocusArea: {}

        },
        fetchedPostFile: {
            isStarted: false,
            isError: false,
            error: '',
            value: ""
        }
    },
    reducers: {
        isStarted_getPostsList: (state) => {
            state.postsList.isStarted = true;
            state.postsList.isError = false;
        },
        success_getPostsList: (state, action) => {
            function extractTechniquesWithValues(obj) {
                const techniques = {};
                function recursiveExtract(currentObj) {
                    for (let key in currentObj) {
                        if (Array.isArray(currentObj[key])) {
                            currentObj[key].forEach(item => {
                                for (let subKey in item) {
                                    if (['fileName', 'tnFileName', 'observation', 'recommendations'].includes(subKey)) continue;
                                    if (!techniques[subKey]) {
                                        techniques[subKey] = [];
                                    }
                                    techniques[subKey].push(item[subKey]);
                                    recursiveExtract(item[subKey]);
                                }
                            });
                        } else if (typeof currentObj[key] === 'object') {
                            recursiveExtract(currentObj[key]);
                        }
                    }
                }

                recursiveExtract(obj);
                return techniques;
            }

            const workAreas = ["strokeTechnique", "footwork"];
            const focusAreas = ["Forehand", "Backhand", "Overhead", "Attack", "Defence"];
            const lowercaseWorkAreas = workAreas.map(item => item.toLowerCase());
            const lowercaseFocusAreas = focusAreas.map(item => item.toLowerCase());

            function extractTech(data) {
                const techniques = {};
                Object.keys(data).forEach(workAreaKey => {
                    const lowerWorkAreaKey = workAreaKey.toLowerCase();
                    if (lowercaseWorkAreas.includes(lowerWorkAreaKey)) {
                        const workAreaData = data[workAreaKey];
                        Object.keys(workAreaData).forEach(focusAreaKey => {
                            const lowerFocusAreaKey = focusAreaKey.toLowerCase();
                            if (lowercaseFocusAreas.includes(lowerFocusAreaKey)) {
                                workAreaData[focusAreaKey].forEach(tech => {
                                    Object.keys(tech).forEach(key => {
                                        techniques[key] = tech[key];
                                    });
                                });
                            }
                        });
                    }
                });

                return techniques;
            }

            // revised code to preserve order
            function extractTechniquesWithFocusArea(obj) {
                const newMap = new Map();
                for (let workArea in obj) {
                    const lowerWorkAreaKey = workArea.toLowerCase();
                    if (lowercaseWorkAreas.includes(lowerWorkAreaKey)) {
                        for (let focusArea in obj[workArea]) {
                            newMap.set(focusArea, obj[workArea][focusArea]);
                        }
                    }
                }
                return convertMapToObject(newMap);
            }

            function convertMapToObject(map) {
                const newObj = {};
                map.forEach((value, key) => {
                    newObj[key] = value;
                });
                return newObj;
            }



            let { payload } = action;
            state.postsList.isError = false;
            state.postsList.isStarted = false;
            state.postsList.error = '';
            state.postsList.value = payload;
            state.postsList.techniques = extractTech(payload)
            state.postsList.TechniquesWithFocusArea = extractTechniquesWithFocusArea(payload)

        },
        error_getPostsList: (state, action) => {
            let { payload } = action;
            state.postsList.isError = true;
            state.postsList.isStarted = false;
            state.postsList.error = payload;
            state.postsList.value = {};
            state.postsList.techniques = {}
            state.postsList.TechniquesWithFocusArea = {}
        },

        isStarted_fetchedPostFile: (state) => {
            state.fetchedPostFile.isStarted = true;
            state.fetchedPostFile.isError = false;
            state.fetchedPostFile.error = '';
            state.fetchedPostFile.value = '';

        },
        success_fetchedPostFile: (state, action) => {
            let { payload } = action;
            state.fetchedPostFile.isError = false;
            state.fetchedPostFile.isStarted = false;
            state.fetchedPostFile.error = '';
            state.fetchedPostFile.value = payload;
        },
        error_fetchedPostFile: (state, action) => {
            let { payload } = action;
            state.fetchedPostFile.isError = true;
            state.fetchedPostFile.isStarted = false;
            state.fetchedPostFile.error = payload;
            state.fetchedPostFile.value = '';
        },
    }
})

export const { isStarted_getPostsList, success_getPostsList, error_getPostsList, isStarted_fetchedPostFile, success_fetchedPostFile, error_fetchedPostFile } = reportSlice.actions
export default reportSlice.reducer