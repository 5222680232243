import { GET_TESTIMONIALS } from "../../config/endpoints";

export const getAllTestimonials = async (formData) => {
    try {
        const url = `${GET_TESTIMONIALS}`;
        const apiUrl = new URL(url);
        const response = await fetch(apiUrl, {
            method: "POST",
            body: JSON.stringify(formData),
            headers: {
                "Content-Type": "application/json"
            }
        });
        const result = await response.json();
        return result;
    } catch (error) {
        throw error;
    }
};

