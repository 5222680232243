import React, { useEffect, useState } from 'react';
import "./Approach.scss";
import approachImg from "../correctApproach.png";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { getFetchReviewPostFile, getFetchReviewPostFileThumbnail } from '../../../redux/network/reportAPI';

export default function CorrectApproach() {
  let NUMBER_COUNT = 0;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  console.log(pathname);

  const reviewedPostData = JSON.parse(localStorage.getItem("reviewPostData"));
  const reviewPostId = reviewedPostData?._id;
  const jwtToken = localStorage.getItem("BQS_TOKEN");

  const { TechniquesWithFocusArea } = useSelector(state => state.report.postsList);
  console.log("TechniquesWithFocusArea", TechniquesWithFocusArea);

  const [thumbnails, setThumbnails] = useState({});
  const [loadingThumbnails, setLoadingThumbnails] = useState({});
  const { technique } = useParams();
  const { techniques } = useSelector(state => state.report.postsList);
  const fetchedPostFile = useSelector(state => state.report.fetchedPostFile);

  const [techniqueData, setTechniqueData] = useState([]);
  const [selectedPlayVideo, setSelectedPlayVideo] = useState("");
  const [parentCategory, setParentCategory] = useState("");

  useEffect(() => {
    if (Object.keys(techniques).length !== 0 && technique) {
      const data = techniques[technique];
      if (data && data.length > 0) {
        setTechniqueData(data);
      } else {
        setTechniqueData(null);
      }
    } else {
      setTechniqueData(null);
    }

    // Find the parent category for the current technique
    const findParentCategory = () => {
      for (const category in TechniquesWithFocusArea) {
        if (TechniquesWithFocusArea[category].some(subTechnique => Object.keys(subTechnique).includes(technique))) {
          setParentCategory(category);
          break;
        }
      }
    };

    if (TechniquesWithFocusArea && technique) {
      findParentCategory();
    }
  }, [techniques, technique, TechniquesWithFocusArea]);

  // Handle thumbnail for each
  useEffect(() => {
    const fetchThumbnails = async () => {
      const thumbnailsData = {};
      const loadingData = {};

      for (const ele of techniqueData) {
        console.log("ele____", ele);

        if (ele?.correct_approach_video && Array.isArray(ele?.correct_approach_video)) {
          for (const item of ele.correct_approach_video) {
            loadingData[item.fileName] = true;
            setLoadingThumbnails(prev => ({ ...prev, ...loadingData }));

            const thumbnailUrl = await getFetchReviewPostFileThumbnail(jwtToken, reviewPostId, item.tnFileName);
            thumbnailsData[item.fileName] = thumbnailUrl;
            loadingData[item.fileName] = false;
            setThumbnails(prev => ({ ...prev, ...thumbnailsData }));
            setLoadingThumbnails(prev => ({ ...prev, ...loadingData }));
          }
        }
      }
    };

    if (techniqueData && techniqueData.length > 0) {
      fetchThumbnails();
    }
  }, [techniqueData, jwtToken, reviewPostId]);

  const handleClickPlayVideo = (fileName) => {
    setSelectedPlayVideo(fileName);
    dispatch(getFetchReviewPostFile(jwtToken, reviewPostId, fileName));
  };

  const getTechniqueIndex = () => {
    return Object.keys(techniques).indexOf(technique);
  };

  const handlePrevious = () => {
    const isFieldCorrectApproach = pathname.split("/").includes("correct-approach");
    const currentIndex = getTechniqueIndex();
    if (isFieldCorrectApproach) {
      navigate(`/reports/${reviewPostId}/${technique}/your-approach`);
      window.scrollTo(0, 0);
    } else {
      if (currentIndex > 0) {
        const previousTechnique = Object.keys(techniques)[currentIndex - 1];
        navigate(`/reports/${reviewPostId}/${previousTechnique}/correct-approach`);
        window.scrollTo(0, 0);
      }
    }
  };

  const handleNext = () => {
    const isFieldYourApproach = pathname.split("/").includes("your-approach");
    const currentIndex = getTechniqueIndex();
    if (isFieldYourApproach) {
      navigate(`/reports/${reviewPostId}/${technique}/correct-approach`);
      window.scrollTo(0, 0);
    } else {
      if (currentIndex < Object.keys(techniques).length - 1) {
        const nextTechnique = Object.keys(techniques)[currentIndex + 1];
        navigate(`/reports/${reviewPostId}/${nextTechnique}/your-approach`);
        window.scrollTo(0, 0);
      }
    }
  };

  const currentIndex = getTechniqueIndex();
  const hasPrevious = currentIndex > 0;
  const hasNext = currentIndex < Object.keys(techniques).length - 1;

  console.log("hasNext", hasNext);

  if (!techniques || Object.keys(techniques).length === 0) {
    return <div>No techniques available.</div>;
  }

  if (!techniqueData) {
    return <div>No data available for the selected technique.</div>;
  }

  return (
    <div>
      <section className='CorrectApproach'>
        <div className='container'>
          <div className='heading'>
            <h1>{parentCategory} {technique}</h1>
          </div>
          <div className='approach_cont'>
            <ol>
              {techniqueData?.map((ele, index) => (
                <div className='approach_box_con' key={index}>
                  {ele?.correct_approach_video?.map((item, videoIndex) => {
                    const thumbnailUrl = thumbnails[item.fileName];
                    const isLoading = loadingThumbnails[item.fileName];
                    NUMBER_COUNT++;
                    return (
                      <div className='approach_box' key={videoIndex}>
                        <li>
                          {selectedPlayVideo === item?.fileName ? (
                            <div className='videoContainer'>
                              {fetchedPostFile.isStarted ? (
                                <div className='loader_error-container'>
                                  <CircularProgress />
                                  <p style={{ color: "#000000" }}>Loading...</p>
                                </div>
                              ) : fetchedPostFile?.isError ? (
                                <div className='loader_error-container'>
                                  <p>{fetchedPostFile?.error}</p>
                                </div>
                              ) : (
                                <video controls autoPlay playsInline>
                                  <source src={fetchedPostFile?.value} type="video/mp4" />
                                  Your browser does not support the video tag.
                                </video>
                              )}
                            </div>
                          ) : (
                            <div className='imageContainer' style={{ backgroundImage: isLoading ? 'none' : `url(${thumbnailUrl || approachImg})` }}>
                              {isLoading ? (
                                <div className='loader_error-container'>
                                  <CircularProgress />
                                  <p style={{ color: "#000000" }}>Loading...</p>
                                </div>
                              ) : (
                                <div onClick={() => handleClickPlayVideo(item?.fileName)}>
                                  <i className="fa-regular fa-circle-play"></i>
                                  <span>Play</span>
                                </div>
                              )}
                            </div>
                          )}
                          <h4>recommendations:</h4>
                          <p className='observation'>{item.recommendations ? item.recommendations : null}</p>
                        </li>
                      </div>
                    );
                  })}
                </div>
              ))}
            </ol>
          </div>
          <div className='button-container'>
            <button onClick={handlePrevious}>Previous <i className="fa-solid fa-arrow-left"></i></button>
            {hasNext && <button onClick={handleNext}>Next  <i className="fa-solid fa-arrow-right"></i></button>}
          </div>
        </div>
      </section>
    </div>
  );
}
