import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { Box, CircularProgress } from '@mui/material';
import React, { useEffect, forwardRef, useState } from 'react';
import { useSelector } from 'react-redux';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog({ open, setOpen }) {
    const videoContent = useSelector(state => state.video.videoContent);

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <div className='videoContainer' style={{ backgroundColor: "black", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", position: "relative", height: "100vh" }}>
                <button aria-label="Close video" style={{ position: "absolute", right: "5%", top: "10px", backgroundColor: "transparent", border: "none", outline: "none", fontSize: "2rem", color: "white" }} onClick={handleClose}>x</button>
                {
                    videoContent.isStartedvideoContent ? (
                        <Box sx={{ display: 'flex', justifyContent: "center", alignItems: "center", color: 'white', height: "100vh" }}>
                            <CircularProgress />
                            <p style={{ paddingLeft: "10px" }}>Loading Video ....</p>
                        </Box>
                    ) : videoContent.isvideoContentError ? (
                        <p style={{ color: "red" }}>{videoContent.videoContentError}</p>
                    ) : (
                        <div>
                            <video controls autoPlay style={{ maxWidth: "100%", maxHeight: "80vh" }}>
                                <source src={videoContent.videoContentUrl} type="video/mp4"></source>
                            </video>
                        </div>
                    )
                }
            </div>
        </Dialog>
    );
}
