import React, { useEffect, useMemo, useState } from 'react'
import './Profile.scss'
import { Box, Button, CircularProgress, TextField } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { UpdateUserProfile, UpdateUserProfilePic, getUserProfile, getUserProfilePic } from '../../redux/network/userApi'
import { useNavigate } from 'react-router-dom'
import { setAlertMessage, setAlertType } from '../../redux/reducers/alertSlice'
import { FaRegEdit } from "react-icons/fa";

const Profile = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const jwtToken = localStorage.getItem("BQS_TOKEN")
    const UserId = localStorage.getItem("BQS__ID")
    console.log("userId", UserId)

    useEffect(() => {
        if (jwtToken) {
            dispatch(getUserProfile(jwtToken))
        } else {
            navigate("/")
        }
    }, [jwtToken])

    useEffect(() => {
        if (jwtToken) {
            dispatch(getUserProfilePic(jwtToken))
        } else {
            navigate("/")
        }
    }, [jwtToken])


    const userData = useSelector((state) => state.user.profile)
    const userPicData = useSelector((state) => state.user.profilePic)

    const { isProfileStarted, isProfilePicError, profilePicError, profilePicUrl } = userPicData
    const { isStarted, isError, error, value } = userData
    const { profileData, phone, email } = value

    console.log("userPicData", userPicData);

    const getFullname = (Fname, Mname, LName) => {
        const firstName = profileData?.name?.firstName;
        const middleName = profileData?.name?.middleName;
        const lastName = profileData?.name?.lastName;

        let fullname = "";

        if (Fname && Fname.length >= 1) {
            fullname += Fname;
        }

        if (Mname && Mname.length >= 1) {
            if (fullname.length > 0) {
                fullname += " " + Mname;
            } else {
                fullname = Mname;
            }
        }

        if (LName && LName.length >= 1) {
            if (fullname.length > 0) {
                fullname += " " + LName;
            } else {
                fullname = LName;
            }
        }
        return fullname;
    };


    console.log("userData", userData)

    const initialState = useMemo(() => {
        return {
            email: email?.email,
            dob: profileData?.dob,
            gender: profileData?.gender,
            height: profileData?.height,
            fullname: getFullname(profileData?.name?.firstName, profileData?.name?.middleName, profileData?.name?.lastName),
            weight: profileData?.weight,
            areasOfImprovement: profileData?.gamingProfile?.areasOfImprovement || [],
            exp: profileData?.gamingProfile?.exp,
            goal: profileData?.gamingProfile?.goal,
            injurySummary: profileData?.gamingProfile?.injurySummary,
            _id: UserId

        };
    }, [userData]);


    const [formData, setFormData] = useState(initialState)
    const [errorMsg, setErrorMsg] = useState("")
    const [isButtonDisable, setisButtonDisable] = useState(true)

    //set the formData when userData change
    useEffect(() => {
        setFormData(initialState);
        setisButtonDisable(true)
    }, [initialState]);

    // console.log("intitalstate", initialState)
    console.log("formData", formData)


    const handleUpdateProfilePIc = (e) => {
        const file = e.target.files[0]
        // const fd = new FormData()
        // fd.append("file", file)
        console.log(file)
        dispatch(UpdateUserProfilePic(jwtToken, file))
    }

    const handleInputChange = (event) => {
        setisButtonDisable(false)
        const { value, name } = event.target;
        console.log(value, name);
        if (name === "dob") {
            // console.log("nameInput", nameInput);
            const nameInput = value.split(" ");

            if (value === "") {
                // Clear error message and update form data
                setErrorMsg("");
                const newData = { ...formData };
                newData["dob"] = value;
                setFormData(newData);
            } else {
                let formattedDate;
                if (value.length < formData["dob"].length) {
                    // Handle backspace when length is decreasing
                    const newData = { ...formData };
                    newData["dob"] = value;
                    setFormData(newData);
                } else if (value.length === 2) {
                    // Day validation
                    if (parseInt(value) > 31) {
                        setErrorMsg("Please enter a correct Day");
                    } else {
                        formattedDate = value + "-";
                        const newData = { ...formData };
                        newData["dob"] = formattedDate;
                        setFormData(newData);
                    }
                } else if (value.length === 5) {
                    // Month validation
                    if (value.slice(3) > 12) {
                        setErrorMsg("Please enter a correct Month");
                    } else {
                        formattedDate = value + "-";
                        const newData = { ...formData };
                        newData["dob"] = formattedDate;
                        setFormData(newData);
                    }
                } else if (value.length === 11) {
                    // Year validation
                    const year = parseInt(value.slice(6));
                    const currentYear = new Date().getFullYear();
                    if (year > currentYear) {
                        setErrorMsg("Please enter a correct Year");
                    }
                } else {
                    const newData = { ...formData };
                    newData["dob"] = value;
                    setFormData(newData);
                }
            }

        } else {
            setFormData({
                ...formData,
                [name]: value
            });
        }

    }


    const handleSaveChanges = () => {
        //name validation
        if (formData.fullname.length <= 0) {
            dispatch(setAlertMessage("Please enter name"))
            dispatch(setAlertType("error"))
            return
        }

        //email validation
        if (!formData.email) {
            dispatch(setAlertMessage("Please enter email"))
            dispatch(setAlertType("error"))
            return;
        }
        if (!/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(formData.email)) {
            dispatch(setAlertMessage("Please enter a valid email address"))
            dispatch(setAlertType("error"))
            return;
        }

        // dob validation
        if (/^\d{2}\-\d{2}\-\d{4}$/.test(formData.dob)) {
            // Parse the entered date string into a Date object
            const parts = formData.dob.split('-');
            const enteredDate = new Date(parts[2], parts[1] - 1, parts[0]); // Note: Month is 0-based
            // Get today's date
            const today = new Date();
            today.setHours(0, 0, 0, 0); // Set time to midnight for comparison
            console.log(enteredDate)
            // Compare the entered date with today's date
            if (enteredDate > today) {
                dispatch(setAlertMessage("Date cannot be greater than today"))
                dispatch(setAlertType("error"))
                return;
            }
        } else {
            dispatch(setAlertMessage("Please enter a valid date in DD-MM-YYYY format"))
            dispatch(setAlertType("error"))
            return;
        }


        //Diving full name in firstName , Middlename and Lastname

        const fullnameArr = formData.fullname.trim().split(" ")

        let firstName = "";
        let middleName = "";
        let lastName = "";

        if (fullnameArr.length === 1) {
            firstName = fullnameArr[0];
        } else if (fullnameArr.length === 2) {
            firstName = fullnameArr[0];
            lastName = fullnameArr[1];
        } else if (fullnameArr.length === 3) {
            firstName = fullnameArr[0];
            middleName = fullnameArr[1];
            lastName = fullnameArr[2];
        } else {
            firstName = fullnameArr[0];
            middleName = fullnameArr[1];
            lastName = fullnameArr.slice(2).join(" "); // Join the rest of the array elements into lastName
        }

        console.log("firstname", firstName)
        console.log("lastname", lastName)
        console.log("middlename", middleName)

        //Update API Call

        // Construct the name object conditionally
        const name = {};
        if (middleName) name.middleName = middleName;
        if (lastName) name.lastName = lastName;


        const body = {
            email: {
                email: formData.email
            },
            profileData: {
                dob: formData.dob,
                gender: formData.gender,
                height: formData.height,
                name: {
                    firstName: firstName,
                    middleName: middleName,
                    lastName: lastName,
                },
                weight: formData.weight,
                gamingProfile: {
                    areasOfImprovement: formData.areasOfImprovement,
                    exp: formData.exp,
                    goal: formData.goal,
                    injurySummary: formData.injurySummary,
                    _id: UserId
                }
            },

        }

        console.log("Body", body)
        dispatch(UpdateUserProfile(jwtToken, body))
    }

    const handleAreaOfImprovement = (item) => {
        setisButtonDisable(false)
        const prevAreasOfImprovement = [...formData.areasOfImprovement]
        const isItemPresent = prevAreasOfImprovement.includes(item);
        const updatedAreasOfImprovement = isItemPresent
            ? prevAreasOfImprovement.filter(area => area !== item)
            : [...prevAreasOfImprovement, item];

        setFormData((prevState) => ({ ...prevState, areasOfImprovement: updatedAreasOfImprovement }))


    }



    return (
        <>
            <section className="bnnrProfile">
                <div className='container'>
                    <h1>My Profile</h1>
                </div>
            </section>
            {
                isStarted ? <Box sx={{ display: 'flex', justifyContent: "center", alignItems: "center", height: "100vh" }}>
                    <CircularProgress />
                    <p>Loading profile details... </p>
                </Box> : isError ? <p className='error-msg'>{error}</p> :
                    <><section className='photo-section'>
                        <div className='maxWidthContainer'>
                            <div className='profile-cont'>
                                <div className='photo-cont'>
                                    {
                                        isProfileStarted ? <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                            <CircularProgress />
                                            <p>Loading Img... </p>
                                        </Box> : <>
                                            <img src={isProfilePicError ? '/assets/dummyProfile.png' : profilePicUrl} alt="Profile" />
                                            <label htmlFor='profileImg'>
                                                <FaRegEdit size={30} />
                                            </label>
                                            <input id='profileImg' type="file" accept="image/*" onChange={handleUpdateProfilePIc} />
                                        </>
                                    }
                                </div>
                                <div className='profile-info-cont'>
                                    <h1>Hi, {profileData?.name?.firstName}</h1>
                                    <span>Update your photo and personal details here.</span>
                                </div>
                            </div>
                        </div>
                    </section>
                        <section className='inputs-section'>
                            <div className='maxWidthContainer'>
                                <div className='input-cont-one'>
                                    <div className='input-field-cont'>
                                        <label htmlFor='name'>Name</label>
                                        <input id='name' type='text' value={formData.fullname} name='fullname' disabled={isStarted} onChange={handleInputChange} onFocus={(e) => e.target.classList.add('focused')} />
                                    </div>
                                    <div className='input-field-cont'>
                                        <label htmlFor='email'>Email</label>
                                        <input id="email" type='text' value={formData.email} name='email' disabled={isStarted} onChange={handleInputChange} />
                                    </div>
                                    <div className='input-field-cont'>
                                        <label htmlFor='phone'>Phone Number</label>
                                        <div className='mobile-number-field-con'>
                                            <select>
                                                <option>+91</option>
                                            </select>
                                            <div></div>
                                            <input id='phone' type='text' value={phone?.number} disabled style={{ width: "100%" }} />
                                        </div>
                                    </div>
                                    <div className='input-field-cont'>
                                        <label htmlFor='dob'>Date of Birth</label>
                                        <input id="dob" type='text' value={formData?.dob} disabled={isStarted} onChange={handleInputChange} name='dob' />
                                    </div>
                                    <div className='input-field-cont'>
                                        <label htmlFor='gender'>Gender</label>
                                        <select id='gender' value={formData?.gender} onChange={handleInputChange} name='gender' disabled={isStarted}>
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                            <option value="Other">Other</option>
                                        </select>
                                    </div>


                                    <div className='input-field-cont'>
                                        <label htmlFor='exp'>Badminton Experience</label>
                                        <select id="exp" value={formData?.exp} onChange={handleInputChange} name='exp' disabled={isStarted}>
                                            {/* <option disabled>Choose any option</option> */}
                                            <option value="Beginner">Beginner</option>
                                            <option value="Intermediate">Intermediate</option>
                                            <option value="Experienced">Experienced</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='input-field-cont'>
                                    <label htmlFor="goals" >What are your goals with badminton?</label>
                                    <textarea id='goals' placeholder='What are your goals with badminton?.......' value={formData?.goal} onChange={handleInputChange} name='goal' disabled={isStarted} maxLength={256} />
                                </div>
                                <div className='input-field-cont'>
                                    <label htmlFor='injurySummary'>Do you have any present or past injuries? If yes, please specify.</label>
                                    <textarea id="injurySummary" placeholder='Do you have any present or past injuries? If yes, please specify....' value={formData?.injurySummary} onChange={handleInputChange} name='injurySummary' disabled={isStarted} />
                                </div>
                                {/* <div className='input-field-cont'>
                                    <label>What areas would you like to improve?</label>
                                    <ul>
                                        {
                                            ["Match Strategy", "Stroke Technique", "Footwork", "Others"].map((item, index) => <li className={`${formData.areasOfImprovement.includes(item) ? "active" : ""}`} onClick={() => handleAreaOfImprovement(item)}>{item}</li>)
                                        }

                                    </ul>
                                </div> */}
                                {/* <div className='input-field-cont'>
                                    <label>Is there anything specific that you would like us to know?</label>
                                    <textarea placeholder='Is there anything specific that you would like us to know?.....' />
                                </div> */}

                                <div className='button-container'>
                                    <button type='button' onClick={handleSaveChanges} disabled={isButtonDisable || isStarted}>SAVE CHANGES</button>
                                </div>

                            </div>

                        </section></>
            }

        </>
    )
}

export default Profile