import React, { useEffect, useState } from 'react';
import styles from './video.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlay } from '@fortawesome/free-regular-svg-icons';
import { Box, Button, CircularProgress, Pagination, Stack } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { deleteGamePost, getAllPostCounts, getFetchGamePostFileThumbnail, getVideos, markGamePostFav } from '../../redux/network/videoApi';
import { Link, useNavigate } from 'react-router-dom';
import FullScreenDialog from './VideoPlayerModel';
import { GET_POST_CONTENT } from '../../config/endpoints';
import thumbnail from './thumbnail.jpg';
import { error_GetFetchVideoContent, isStarted_GetFetchVideoContent, success_GetFetchVideoContent } from '../../redux/reducers/videoSlice';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { setAlertMessage, setAlertType } from '../../redux/reducers/alertSlice';
import CustomDropdown from '../CustomeDropdown/CustomDropdown';
import { buyReviewCredits, getUserProfile } from '../../redux/network/userApi';

const dataFormatter = (dateString) => {
  const inputDate = new Date(dateString);

  const monthNames = [
    "January", "February", "March",
    "April", "May", "June", "July",
    "August", "September", "October",
    "November", "December"
  ];

  const day = inputDate.getDate();
  const monthIndex = inputDate.getMonth();
  const year = inputDate.getFullYear();
  const hours = inputDate.getHours();
  const minutes = inputDate.getMinutes();

  return `${day} ${monthNames[monthIndex]} ${year} ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
}

// Helper function to calculate the start and end of time ranges
const getTimeRange = (range) => {
  const now = new Date();
  let start, end;
  switch (range) {
    case 'lastOneWeek':
      start = new Date(now.setDate(now.getDate() - 7));
      end = new Date();
      break;
    case 'lastMonth':
      start = new Date(now.setMonth(now.getMonth() - 1));
      end = new Date();
      break;
    case 'last3Months':
      start = new Date(now.setMonth(now.getMonth() - 3));
      end = new Date();
      break;
    default:
      start = null;
      end = null;
  }
  return { start: start?.getTime(), end: end?.getTime() };
}

export default function Video() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const jwtToken = localStorage.getItem("BQS_TOKEN");
  const userId = localStorage.getItem("BQS__ID");

  const { isStarted, isError, error, data } = useSelector(state => state.video.video);
  const postCountData = useSelector(state => state.video.videosCount);
  const userData = useSelector((state) => state.user.profile);
  const remainingReview = userData?.value?.profileData?.membershipProfile?.remainingReviews || 0;


  const [open, setOpen] = useState(false);
  const [videoThumbnails, setVideoThumbnails] = useState({});
  const [limit, setLimit] = useState(3);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [skip, setSkip] = useState(0);

  console.log("count", count)
  console.log("page", page)


  const [sortOption, setSortOption] = useState('created');
  const [sortOrder, setSortOrder] = useState(1);
  const [updateTrigger, setUpdateTrigger] = useState(false);

  // const dropdownOptions = [
  //   { value: 'newToOld', label: 'Date: new to old' },
  //   { value: 'oldToNew', label: 'Date: old to new' },
  //   { value: 'created', label: 'Uploaded' },
  //   { value: 'reviewRequested', label: 'Requested' },
  //   { value: 'reviewed', label: 'Completed' },
  //   { value: 'favourites', label: 'Favourites' },
  // ];

  const dropdownOptions = [
    { value: 'created', label: 'Uploaded' },
    { value: 'reviewRequested', label: 'Requested' },
    { value: 'reviewed', label: 'Completed' },
    { value: 'favourites', label: 'Favourites' },
  ];



  useEffect(() => {
    switch (sortOption) {
      case 'oldToNew':
        setSkip(0)
        setPage(1)
        break;
      case 'newToOld':
        setSkip(0)
        setPage(1)
        break;
      case 'created':
        setSkip(0)
        break;
      case 'reviewRequested':
        setSkip(0)
        break;
      case 'reviewed':
        setSkip(0)
        break;
      case 'favourites':
        setSkip(0)
        break;
      default:
      // setSortOrder(1);
    }
  }, [sortOption]);

  useEffect(() => {
    const videoCount = Number(postCountData?.data?.count);
    setCount(Math.ceil(videoCount / limit));
  }, [jwtToken, userId, skip, limit, count, updateTrigger, sortOption, dispatch]);


  //to get remainingReviews
  useEffect(() => {
    if (jwtToken) {
      dispatch(getUserProfile(jwtToken))
    }
  }, [jwtToken, dispatch])

  const handleChange = (event, value) => {
    setPage(value);
    setSkip((value - 1) * limit);
    window.scrollTo(0, 0);
  };



  // Function to construct the conditions
  const constructConditions = () => {
    const { start, end } = getTimeRange(sortOption);
    let conditions = { "poster": userId };

    // Add time range condition if applicable
    if (start && end) {
      conditions.createdAt = { start, end };
    }


    if (sortOption === "created") {
      conditions.state = "created"
    } else if (sortOption === "reviewRequested") {
      conditions.state = "review_requested"
    } else if (sortOption === "reviewed") {
      conditions.state = "reviewed"
    } else if (sortOption === "favourites") {
      conditions.fav = true;
    }

    return conditions;
  }



  //All Posts Count
  useEffect(() => {
    if (jwtToken && userId) {
      const conditions = constructConditions();
      dispatch(getAllPostCounts(jwtToken, {
        "condition": conditions,
      }));
    }
  }, [jwtToken, userId, skip, limit, count, updateTrigger, sortOption, dispatch]);



  //All Posts Details
  useEffect(() => {

    if (jwtToken && userId) {
      const conditions = constructConditions();

      dispatch(getVideos(jwtToken, {
        "condition": conditions,
        "sort": {
          "createdAt": 1
        },
        "skip": skip,
        "limit": limit,
      }));
    }
  }, [jwtToken, userId, skip, limit, count, updateTrigger, sortOption, dispatch]);


  useEffect(() => {
    if (data.length > 0 && jwtToken) {
      const fetchThumbnail = async (reviewPostId, tnPath) => {
        try {
          const thumbnailUrl = await getFetchGamePostFileThumbnail(jwtToken, reviewPostId, tnPath);
          setVideoThumbnails(prev => ({ ...prev, [reviewPostId]: thumbnailUrl }));
        } catch (error) {
          console.error('Error fetching thumbnail:', error);
        }
      };
      data.forEach((item) => {
        fetchThumbnail(item._id, item.game?.tnPath);
      });
    }
  }, [data, jwtToken]);

  async function handlePlayClick(postId, isOpen = true) {
    const url = `${GET_POST_CONTENT}?postId=${postId}&type=game`;
    dispatch(isStarted_GetFetchVideoContent());
    setOpen(isOpen);
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          'Authorization': `Bearer ${jwtToken}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch video');
      }

      const reader = response.body.getReader();
      const stream = new ReadableStream({
        start(controller) {
          return pump();
          function pump() {
            return reader.read().then(({ done, value }) => {
              if (done) {
                controller.close();
                return;
              }
              controller.enqueue(value);
              return pump();
            });
          }
        }
      });
      const responseBlob = await new Response(stream).blob();
      const file = new File([responseBlob], 'video.mp4', { type: 'video/mp4' });
      const fr = new FileReader();
      fr.readAsDataURL(file);
      fr.addEventListener("load", () => {
        const res = fr.result;
        dispatch(success_GetFetchVideoContent(res));
      });
    } catch (error) {
      dispatch(error_GetFetchVideoContent(error.message || "Error while fetching video"));
      console.error('Error fetching video:', error);
    }
  }

  function RenderNoVideosView() {
    return (
      <div className={styles.no_video_view_cont}>
        <div>
          <h1>No Videos uploaded .....</h1>
          <p>Click <Link to="/upload">here</Link> to upload your gameplay video and get a detailed report.</p>
        </div>
      </div>
    );
  }

  function RenderNoVideosWithSearchCondition() {
    return (
      <div className={styles.no_video_view_cont}>
        <div>
          <p>No posts found with the provided search key</p>
        </div>
      </div>
    );
  }

  function handleClickViewReport(postData) {
    localStorage.setItem("reviewPostData", JSON.stringify(postData));
    navigate(`/reports/${postData._id}`);
  }

  function handleClickSubmitReport(postData) {
    if (remainingReview > 0) {
      navigate(`/choose_plan`);
      localStorage.setItem("reviewPostId", postData._id);
    } else {
      dispatch(setAlertMessage("You have no review credits left. Please purchase more to continue."))
      dispatch(setAlertType("error"))
    }
  }

  async function handleClickFav(postData) {
    let favToggle = !postData.fav;
    const res = await markGamePostFav(jwtToken, postData._id, favToggle);

    if (res && res.code === 200) {
      const conditions = constructConditions();

      dispatch(getVideos(jwtToken, {
        "condition": conditions,
        "sort": {
          "createdAt": 1
        },
        "skip": skip,
        "limit": limit,
      }));
    } else {
      dispatch(setAlertMessage(res.message || "Something went wrong"));
      dispatch(setAlertType("error"));
    }
  }

  async function handleClickDel(postData) {
    const res = await deleteGamePost(jwtToken, postData._id);

    if (res && res.code === 200) {
      dispatch(getAllPostCounts(jwtToken));

      // Check if current page is empty after deletion
      if (data.length === 1 && page > 1) {
        setPage(prevPage => prevPage - 1);
        setSkip(prevSkip => prevSkip - limit);
      }

      setUpdateTrigger(prev => !prev); // Toggle updateTrigger to re-render component

    } else {
      dispatch(setAlertMessage(res.message || "Something went wrong"));
      dispatch(setAlertType("error"));
    }
  }

  const handleSortChange = (newSortOption) => {
    setSortOption(newSortOption);
    setPage(1)
    setSkip(0)
    setUpdateTrigger(prev => !prev); // Trigger update to refetch data
  };

  async function handleClickBuyCredit() {
    const res = await buyReviewCredits(jwtToken);

    if (res && res.code === 200) {
      dispatch(getUserProfile(jwtToken));
      dispatch(setAlertMessage(res.message || "Credit purchased successfully"));
      dispatch(setAlertType("success"));
    } else {
      dispatch(setAlertMessage(res.message || "Something went wrong"));
      dispatch(setAlertType("error"));
    }
  }

  return (
    <>
      <FullScreenDialog open={open} setOpen={setOpen} />
      <section className={styles.bnnr}>
        <div className='container'>
          <h1>My Journal</h1>
        </div>
      </section>

      <div className={styles.videos_section}>

        {jwtToken && (
          <>
            <div className={styles.banner_info}>
              <p>You can only generate 2 reports per week</p>
            </div>
            <div className='container'>
              <div className={styles.header_section}>
                <CustomDropdown
                  options={dropdownOptions}
                  value={sortOption}
                  onChange={handleSortChange}
                />
                <div className={styles.cartCont}>
                  <div className={styles.cartContent}>
                    <div className={styles.spanOne}>Reports remaining</div>
                    <div className={styles.spanTwo}>{remainingReview}/12</div>
                  </div>

                  <div className={styles.cartIcon} onClick={() => handleClickBuyCredit()}>
                    <i className="fa-solid fa-cart-plus"></i>
                  </div>

                </div>
              </div>
            </div>
            {isStarted ? (
              <Box sx={{ display: 'flex', justifyContent: "center", alignItems: "center", height: "25vh" }}>
                <CircularProgress /> <p>Loading....</p>
              </Box>
            ) : isError ? (
              <Box sx={{ display: 'flex', justifyContent: "center", alignItems: "center", height: "25vh" }}>
                <p className={styles.error}>{error}</p>
              </Box>
            ) : data.length > 0 ? (
              <div className='container'>
                <div className={styles.wrp}>
                  {data.map((ele, index) => {
                    // console.log(`Video ${index}`, ele);
                    const thumbnailUrl = videoThumbnails[ele._id] || thumbnail;

                    return (
                      <div className={styles.video_wrapper} key={ele._id}>
                        <div className={styles.data_icons_container}>
                          <div className={styles.date_cont}>
                            <span></span>
                            <p>{dataFormatter(ele.createdAt)}</p>
                          </div>

                          <div className={styles.icons_cont}>

                            {ele.fav ? <i className="fa-solid fa-bookmark" onClick={() => handleClickFav(ele)}></i> : <i className="fa-regular fa-bookmark" onClick={() => handleClickFav(ele)}></i>}
                            <i className="fa-solid fa-delete"></i>
                            <DeleteForeverIcon sx={{ color: "#247E45", cursor: "pointer" }} onClick={() => handleClickDel(ele)} />
                          </div>

                        </div>

                        <div className={styles.video_box} >
                          <div className={styles.video_cont} style={{ backgroundImage: `url(${thumbnailUrl})` }}>
                            {
                              ele.state === "reviewed" ? <Button onClick={() => handleClickViewReport(ele)}>View report</Button> : ele.state === "created" ? <Button onClick={() => handleClickSubmitReport(ele)}>Ready to Submit</Button> : <Button style={{ backgroundColor: "#E0E1E6", color: "#000000", }} disabled={true}>Waiting on Feedback</Button>
                            }
                          </div>
                          <div className={styles.icons}>
                            <div className={styles.play_icon} onClick={() => handlePlayClick(ele._id)}>
                              <FontAwesomeIcon icon={faCirclePlay} /><span>Play</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <Stack spacing={2} sx={{ marginBlock: "2rem", alignItems: "end" }}>
                  <Pagination count={count} page={page} onChange={handleChange} size='large' />
                </Stack>
              </div>
            ) : (
              sortOption == "favourites" || sortOption == "created" || sortOption == "reviewRequested" || sortOption == "reviewed" ? <RenderNoVideosWithSearchCondition /> : <RenderNoVideosView />
            )}
          </>
        )}
        {!jwtToken && <RenderNoVideosView />}
      </div>
    </>
  );
}
